﻿@use '../custom/' as *;
@use '../mixins' as *;

.navigation-links {
	list-style: none;
	padding: 0;
	margin: 0;

	.navigation-links__item {
		@include font-navigation-link;
		position: relative;
		display: block;
		padding: 0 0 0 rem(32px);
		margin: 0 0 rem(18px);
		color: $color-tritary;

		&:before {
			position: absolute;
			content: '';
			width: rem(16px);
			height: rem(16px);
			border: #fff solid 3px;
			border-radius: 50%;
			top: rem(7px);
			left: 0;
			transition: background $transition-time ease;
		}

		&:hover,
		&:focus,
		&.is-active {

			&:before {
				background: #fff;
			}
		}
	}

	&.has-first-acive {

		li:first-child .navigation-links__item {

			&:before {
				background: #fff;
			}
		}
	}
}