﻿@forward 'fonts';
@forward 'icon';

$color-primary: #8000ff;
$color-secondary: #ff0843;
$color-tritary: #b8ff4e;
$color-yellow: #ffc700;
$color-light-yellow: #fff6d7;

$color-news-list: #eaf6ff;

$color-text: #500101;

$transition-time: 0.3s;

$border-radius: rem(28px);