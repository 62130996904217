﻿@use "sass:math";
@use '../custom' as *;
@use '../mixins/rem' as *;
@use '../mixins/breakpoints' as *;

.news-message {
	position: relative;
	z-index: 0;
	display: flex;
	align-items: flex-end;
	min-height: rem(438px);
	border-radius: rem(28px);
	overflow: hidden;
	color: #fff;

	.news-message__content {
		padding: rem(38px) rem(32px);
	}

	.date {
		color: $color-tritary;
		font-weight: 300;
	}

	.news-message__image {
		position: absolute;
		z-index: -2;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
		transform: scale(1);
		transition: transform $transition-time ease;
	}

	&.has-no-image {
		background: $color-primary;
	}

	&:not(.has-no-image) {

		&:before {
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: rem(218px);
			bottom: 0;
			left: 0;
			background: linear-gradient(0deg, rgba(80,1,1,1) 0%, rgba(80,1,1,0) 75%);
			opacity: 0.5;
		}
	}

	&.has-no-image {

		&:before {
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			background: url(../../images/placeholder.jpg);
			background-size: cover;
		}

		&:after {
			position: absolute;
			z-index: -1;
			content: '';
			width: rem(110px);
			height: rem(120px);
			top: rem(32px);
			right: rem(32px);
			background: url(../../images/footer-vector-logo.png);
			background-size: cover;
		}
	}

	&:hover,
	&:focus {

		.news-message__image {
			transform: scale(1.05);
		}
	}
}

.news-list {
	list-style: none;
	padding: 0;
	border-radius: rem(28px);
	margin: rem(60px) 0 0;
	overflow: hidden;

	@include create-breakpoint(t) {
		margin: rem(90px) 0 0;
	}

	@include create-breakpoint(l) {
		margin: rem(120px) 0 0;
	}

	.news-list__item {
		display: block;
		background: $color-news-list;
		border-bottom: 1px solid rgba($color-text, 0.1);
		transition: background $transition-time ease;

		@include create-breakpoint(t) {
			display: flex;
		}

		.date {
			padding: rem(24px) rem(32px);
			flex-shrink: 0;
		}

		h2 {
			flex-grow: 1;
			padding: rem(24px) rem(32px);
			font-weight: normal;
		}

		.news-list__item-text {
			@include font-text-bigger;
			padding: rem(24px) rem(32px);
			color: $color-primary;
			flex-shrink: 0;

			span {
				position: relative;

				&:before,
				&:after {
					position: absolute;
					content: '';
					width: 100%;
					height: 2px;
					bottom: rem(-4px);
					left: 0;
					background: $color-primary;
				}

				&:after {
					width: 0;
					height: rem(4px);
					bottom: rem(-5px);
					transition: width $transition-time ease;
				}
			}

			&:hover,
			&:focus {

				span:after {
					width: 100%;
				}
			}
		}

		&:hover,
		&:focus {
			background: darken($color-news-list, 10%);
		}
	}

	li:last-child a {
		border-bottom: none;
	}
}