﻿@use '../custom' as *;
@use '../mixins' as *;
@use '../elements/hamburger' as *;

#nav {
	display: none;

	&:checked {

		~ .header-navigation .hamburger {
			@extend %hamburger-active;
		}

		~ .nav,
		~ .nav-backdrop {
			visibility: visible;
			opacity: 1;
		}

		~ .nav {
			.nav__container {
				.columns {
					&:before {
						animation: pop-in-element .2s .35s ease-out forwards;
					}

					&:after {
						animation: pop-in-logo-element .2s ease-out forwards;
					}

					.column:first-child {

						.nav-content:before {
							animation: pop-in-logo-element .2s .2s ease-out forwards;
						}

						&:after {
							animation: pop-in-element .2s .3s ease-out forwards;
						}
					}
				}
			}
		}
	}
}

.nav {
	position: fixed;
	z-index: 100;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: $color-primary;
	color: #fff;
	visibility: hidden;
	opacity: 0;
	transition: visibility $transition-time ease, opacity $transition-time ease;

	.nav__wrapper {
		position: relative;
		height: 100%;
		overflow-y: auto;
	}

	.nav__container {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		width: 100%;
		height: 100%;

		@media only screen and (max-width: 1023px) {
			max-height: calc(100% - 52px);
			overflow: scroll;
		}
	}

	nav {
		width: 100%;

		.columns {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;

			@media only screen and (max-width: 1023px) {
				padding-top: 10%;
				flex-direction: column-reverse;
			}

			@include create-breakpoint(l) {
				height: 100%;

				&:before {
					position: absolute;
					content: "";
					background: url(../../images/egg.png) no-repeat;
					pointer-events: none;
					width: rem(324px);
					height: rem(290px);
					right: rem(-98px);
					bottom: rem(-72px);
					transform: scale(0);
					opacity: 0;
				}
			}

			@include create-breakpoint(ll) {
				&:after {
					position: absolute;
					content: "";
					background: url(../../images/header-vector-logo.png) no-repeat;
					pointer-events: none;
					width: rem(582px);
					height: rem(582px);
					top: 50%;
					left: 0;
					transform: translate(-50%,-50%);
					transform: scale(0);
					opacity: 0;
				}
			}

			.column {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				height: 100%;
				padding: rem(120px) 0 rem(48px);
			}

			.column:first-child {
				position: relative;

				@include create-breakpoint(l) {

					&:after {
						position: absolute;
						content: "";
						background: url(../../images/banana/banana-280.png) no-repeat;
						pointer-events: none;
						width: rem(280px);
						height: rem(222px);
						right: 0;
						bottom: rem(64px);
						transform: scale(0);
						opacity: 0;
					}

					.nav-content {
						&:before {
							position: absolute;
							content: "";
							background: url(../../images/strawberry.png) no-repeat;
							background-size: contain;
							pointer-events: none;
							width: rem(95px);
							height: rem(117px);
							left: rem(-20px);
							top: rem(-120px);
							z-index: -1;
							transform: scale(0);
							opacity: 0;
						}
					}
				}

				@include create-breakpoint(ll) {
					align-items: unset;
				}

				.nav-content {
					position: relative;
					padding: rem(24px);
					z-index: 1;

					@include create-breakpoint(l) {
						width: 57%;
						max-width: 100%;
					}

					@include create-breakpoint(ll) {
						margin-left: 36.5%;
					}

					.nav-text {
						@include font-menu-text;
						margin-bottom: rem(48px);
					}

					.nav-contact .contact-information {
						color: $color-tritary;
						margin-bottom: rem(48px);
					}
				}
			}

			.navigation {
				list-style: none;
				width: 100%;
				padding: rem(24px);

				@include create-breakpoint(l) {
					max-width: 62%;
				}

				li {
					position: relative;

					&.is-active {
						color: $color-tritary;
					}

					a {
						@include font-menu-link;
						display: block;
						width: 100%;

						> span {
							display: flex;
							justify-content: center;
							padding: rem(21px) rem(26px);

							@include create-breakpoint(l) {
								display: inline-block;
							}
						}

						.vacancies-count {
							position: relative;
							display: inline-block;
							padding: 0;
							top: rem(-4px);
							left: rem(16px);
						}

						&:hover span {
							color: $color-tritary;

							.vacancies-count {
								color: $color-primary;
							}
						}
					}

					&:after, &:first-child:before {
						position: absolute;
						content: "";
						height: 1px;
						width: 100%;
						bottom: 0;
						left: 0;
						background: #9933ff;
					}

					&:first-child:before {
						top: 0;
					}
				}
			}
		}
	}

	.languages {

		@include create-breakpoint(l) {
			display: none;
		}
	}
}

@keyframes pop-in-element {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-in-logo-element {
	0% {
		transform: translate(-50%,-50%) scale(0);
		opacity: 0;
	}

	100% {
		transform: translate(-50%,-50%) scale(1);
		opacity: 1;
	}
}