﻿@use '../mixins/rem' as *;

.logo {
	z-index: 0;
	display: block;
	width: rem(119px);
	height: rem(44px);
	text-indent: -9999px;
	background: url(../../images/logo-circ.svg) center no-repeat;
	background-size: contain;

	.home &,
	.page-product &,
	.has-no-header-image &,
	&.is-white,
	.page-mission & {
		background-image: url(../../images/logo-circ-white.svg)
	}

	&.is-purple {
		background: url(../../images/logo-circ.svg) center no-repeat;
	}
}
