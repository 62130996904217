﻿@use '../custom/' as *;
@use '../mixins' as *;

.legal {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0;
	list-style: none;
	color: #fff;
	opacity: 0.5;

	li {
		@include font-caption;
		white-space: nowrap;
		margin-right: rem(16px);

		a {
			@include underline-effect-base(#fff, $bottom: 0, $height: 1px);
			text-decoration: none;

			&:hover {
				@include underline-effect-hover();
			}
		}
	}
}
