﻿@use '../custom' as *;
@use '../mixins' as *;

.industry-slider {
	padding-top: rem(120px);
	padding-bottom: rem(40px);

	@include create-breakpoint(t) {
		padding-top: rem(160px);
	}

	@include create-breakpoint(l) {
		padding-top: rem(240px);
	}

	.container {
		position: relative;
		max-width: rem(1096px);
	}

	.industry-slider__slide {
		border-radius: rem(30px);
		overflow: hidden;
		background: $color-news-list;
		box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.05);

		.media {
			position: relative;
			z-index: 0;
			border-radius: rem(30px);
			min-height: rem(320px);

			&:after {
				position: absolute;
				z-index: 1;
				background: linear-gradient(90deg, rgba(80,1,1,1) 0%, rgba(255,122,0,1) 100%);
				content: '';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				opacity: 0.4;
			}

			figcaption {
				@include font-text-bigger;
				position: absolute;
				z-index: 2;
				width: 100%;
				max-width: rem(840px);
				padding: rem(16px) rem(20px);
				bottom: 0;
				left: 0;
				color: #fff;

				@include create-breakpoint(t) {
					padding: rem(26px) rem(36px);
				}

				@include create-breakpoint(l) {
					@include font-title;
					padding: rem(38px) rem(52px);
				}
			}
		}
	}

	.industry-slider__content {
		padding: rem(44px) rem(48px);

		@include create-breakpoint(t) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.industry-slider__hgroup {
		margin: 0 0 rem(16px);

		@include create-breakpoint(t) {
			margin: 0 0 0;
		}

		span {
			@include font-menu-text;
			display: block;
			color: $color-primary;
			margin: 0 0 rem(-6px);
		}

		.title {
			@include font-title-industry;
		}
	}

	.industry-slider__link {
		@include font-menu-text;
		@include underline-effect-base($color-primary, rem(4px), rem(2px));
		color: $color-primary;

		&:before {
			transform: scaleX(1);
		}

		&:hover:before {
			transform: scaleX(0);
		}
	}

	.swiper-container {
		position: relative;

		@include create-breakpoint(lm) {

			.swiper-slide {
				transition-property: margin, transform !important;
			}

			.swiper-slide.swiper-slide-next + .swiper-slide {
				margin-left: rem(48px);
			}

			.swiper-slide.swiper-slide-next + .swiper-slide + .swiper-slide {
				margin-left: rem(48px);
			}

			.swiper-slide.swiper-slide-next + .swiper-slide + .swiper-slide + .swiper-slide {
				margin-left: rem(48px);
			}
		}
	}

	.swiper-button-container {
		position: absolute;
		top: rem(16px);
		left: rem(32px);

		@include create-breakpoint(lm) {
			top: auto;
			bottom: 0;
			left: rem(-78px);
		}

		.swiper-button-prev {
			margin: 0 0 rem(16px) !important;
		}
	}

	.swiper-pagination {
		bottom: rem(-38px) !important;
	}

	.round-text {
		position: absolute;
		top: rem(-100px);
		left: rem(-80px);

		@include create-breakpoint(ds) {
			top: rem(-200px);
			left: rem(-80px);
		}

		@include create-breakpoint(ds) {
			top: rem(-110px);
			left: rem(-280px);
		}
	}

	.custom-swiper.custom-swiper-small .swiper-pagination {
		position: relative;
		margin: 0 auto;
		left: auto;
		transform: none;
	}
}