﻿@use '../custom' as *;
@use '../mixins' as *;

.bio-transformer-block {
	position: relative;
	padding: 0 0 rem(180px);
	color: #fff;

	.bio-transformer-block__content {
		display: flow-root;
		padding: rem(60px) 0;
		background: $color-primary;

		@include create-breakpoint(t) {
			padding: rem(80px) 0;
		}

		@include create-breakpoint(l) {
			padding: rem(120px) 0;
		}

		@include create-breakpoint(ds) {
			padding: rem(120px) 0 rem(418px);
		}
	}

	.title {
		margin: 0 0 rem(54px);
	}

	.bio-transformer-block__sub-title {
		@include font-title;
		color: $color-tritary;
		margin: rem(-54px) 0 rem(54px);
	}

	.container {
		max-width: rem(816px);
	}

	.content {
		max-width: rem(636px);
	}

	.bio-transformer-block__image {
		width: calc(100% - 1.25rem);
		max-width: rem(1068px);
		float: right;
		margin: rem(32px) 0 rem(-184px);

		@include create-breakpoint(t) {
			margin: rem(32px) 0 rem(-224px);
		}

		@include create-breakpoint(l) {
			margin: rem(32px) 0 rem(-264px);
		}

		@include create-breakpoint(ds) {
			position: absolute;
			margin: 0;
			right: 0;
			bottom: 34px;
		}
	}

	.round-text.is-our-machine {
		display: none;
		position: absolute;
		top: rem(160px);
		left: rem(-360px);

		@include create-breakpoint(ds) {
			display: block;
		}

		@include create-breakpoint(ds) {
			left: rem(-180px);
		}

		@include create-breakpoint(d) {
			left: rem(-148px);
		}
	}
}