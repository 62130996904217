﻿@use '../custom' as *;
@use '../mixins' as *;
@use "sass:math";

.social-flippers {
	position: fixed;
	width: 75%;
	bottom: 0;
	left: 0;
	z-index: 101;

	@include create-breakpoint(l) {
		z-index: 99;
		width: auto;
		top: 50vh;
		right: 0;
		bottom: auto;
		left: auto;
		transform: translate(calc(100% - 4rem), -50%);
		background: #fff;
		border-top-left-radius: rem(30px);
		border-bottom-left-radius: rem(30px);
		box-shadow: 4px 9px 30px 0px rgba(0, 0, 0, 0.2);
	}

	.has-no-overflow & {

		@include create-breakpoint(l) {
			right: var(--sw);

			li {
				opacity: 0;
			}
		}
	}

	.contact-information {
		display: flex;
		width: 100%;
		background: $color-primary;

		@include create-breakpoint(l) {
			display: initial;
			width: auto;
			background: #fff;
			border-top-left-radius: rem(30px);
			border-bottom-left-radius: rem(30px);
		}

		li {
			position: relative;
			width: math.div(100%, 3);

			@include create-breakpoint(l) {
				width: auto;
			}

			a {
				height: rem(64px);
				transition: all .25s ease-in-out;
				margin: unset;
				color: #fff;

				@include create-breakpoint(l) {
					background: #fff;
					height: auto;
					padding: rem(16px) rem(16px) rem(16px) rem(64px);
					color: $color-primary;
				}

				span {
					display: none;

					@include create-breakpoint(l) {
						display: inline;
					}
				}

				&:after {
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: rem(28px);

					@include create-breakpoint(l) {
						left: rem(20px);
						transform: translateY(-50%);
						font-size: rem(24px);
					}
				}

				&:hover,
				&:focus {

					@include create-breakpoint(l) {
						transform: translateX(calc(-100% + 4rem));
						transition: all .25s ease-out;
						box-shadow: 4px 9px 30px 0px rgba(0, 0, 0, 0.2);
					}
				}
			}

			&:first-child a {
				border-top-left-radius: rem(30px);
			}

			&:last-child a {
				border-bottom-left-radius: rem(30px);
			}
		}

		li:not(:last-child) {

			&:after {

				@include create-breakpoint(l) {
					position: absolute;
					content: "";
					height: 1px;
					bottom: 0;
					width: calc(100% - 30px);
					background: $color-news-list;
				}
			}
		}
	}
}
