﻿@use '../mixins' as *;
@use '../custom' as *;

.input {
	width: 100%;
	min-height: rem(64px);
	padding: 0 rem(40px);
	overflow: hidden;
	border: 0;
	transition: all $transition-time ease-in-out;
	line-height: rem(64px);
	border: 1px solid #fff;
	border-radius: rem(28px);
	box-shadow: none;
	outline: none;
	color: $color-text;
	font-weight: normal;

	.has-dark-blue-background & {
		border: 1px solid #fff;
	}

	&::placeholder {
		opacity: 1;
		color: rgba($color-text, 0.5);
		font-weight: normal;
	}

	&:hover,
	&:focus {
		border: 1px solid $color-text;
		box-shadow: none;
		outline: none;
	}
}
