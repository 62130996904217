﻿@use '../custom' as *;
@use '../mixins' as *;

.languages__checkbox {
	position: absolute;
	z-index: -1;
	width: 1px;
	height: 1px;
	top: 0;
	right: 0;
	-webkit-appearance: none;

	&:checked + .languages {

		dt {
			background: #8b29fe;
		}

		dd {
			display: block;
		}
	}
}

.languages {
	position: relative;
	width: 100%;
	color: #fff;
	max-width: rem(432px);

	@include create-breakpoint("l") {
		position: fixed;
		z-index: 101;
		max-width: rem(600px);
		width: auto;
		top: rem(62px);
		right: rem(232px);
		margin: 0;

		.has-no-overflow & {
			right: calc(14.5rem + var(--sw));
		}
	}

	dl {
		position: relative;
		overflow: hidden;
		width: rem(72px);
		height: rem(50px);
	}

	dt {
		position: relative;
		z-index: 1;
		display: none;

		@include create-breakpoint("l") {
			display: block;
		}

		label {
			display: block;
			text-indent: -99999px;
			width: rem(60px);
			height: rem(42px);
			padding: 0 rem(18px) 0 0;
			cursor: pointer;

			&:after {
				@include icon($icon-globe);
				position: absolute;
				font-size: rem(18px);
				top: 50%;
				left: rem(12px);
				transform: translateY(-50%);
				text-indent: 0;
			}

			&:before {
				@include icon($icon-arrow-bold-down);
				position: absolute;
				font-size: rem(10px);
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				text-indent: 0;
				right: rem(11px);
			}
		}
	}

	&:hover {

		dt {
			background: #8b29fe;
		}

		dd {
			display: block;
		}
	}

	dd {
		z-index: 0;
		margin: 0;
		text-align: center;

		@include create-breakpoint("l") {
			position: absolute;
			display: none;
			top: 100%;
			right: 0;
			background: #8b29fe;
			text-align: left;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			@include create-breakpoint("l") {
				box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
			}
		}

		li:first-child a {
			border-top: 1px solid #93f;
		}

		a {
			padding: rem(15px) rem(30px) rem(14px);
			display: block;
			text-decoration: none;
			border-bottom: 1px solid #93f;

			span {
				@include underline-effect-base(#fff, -2px, 1px);
			}

			@include create-breakpoint("l") {
				border-bottom: none;
			}

			&:hover,
			&:focus {

				span {
					@include underline-effect-hover();
				}
			}
		}
	}
}
