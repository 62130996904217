﻿@use '../custom' as *;
@use '../mixins/rem' as *;
@use '../mixins/breakpoints' as *;
@use 'dropdown' as *;

.header {
	position: relative;
	z-index: 1;
	display: flex;
	flex-wrap: wrap;
	padding: rem(160px) 0 rem(60px);

	@include create-breakpoint(t) {
		padding: rem(160px) 0 rem(90px);
	}

	@include create-breakpoint(l) {
		padding: rem(160px) 0 rem(120px);
	}

	@include create-breakpoint(ll) {
		padding: 0 0 rem(120px);
		min-height: rem(680px);
	}

	.header__content-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 0 rem(32px);

		@include create-breakpoint(ts) {
			width: 77%;
		}

		@include create-breakpoint(ll) {
			width: 37.5%;
			padding: 0;
		}
	}

	.header__content-container.has-logo {
		position: absolute;
		top: rem(48px);
		left: 0;

		.header__content {
			width: 100%;

			@include create-breakpoint-max(ll) {
				max-width: rem(572px);
			}
		}
	}

	.header__content {
		padding: 0 rem(20px);

		@include create-breakpoint-max(ll) {
			max-width: rem(572px);
			width: 100%;
		}

		@include create-breakpoint(ll) {
			max-width: rem(560px);
			width: 100%;
		}
	}

	.header__image {
		position: relative;
		width: 100%;
		height: rem(340px);
		overflow: hidden;

		@include create-breakpoint(l) {
			width: 80%;
			height: rem(510px);
			margin: 0 0 0 auto;
			border-radius: 0 0 0 rem(28px);
		}

		@include create-breakpoint(ll) {
			width: 62.5%;
			height: rem(680px);
		}
	}

	.title.is-big {
		color: $color-primary;
	}

	&:before {
		position: absolute;
		z-index: -2;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: $color-tritary;

		@include create-breakpoint(ts) {
			width: 77%;
			border-radius: 0 0 rem(28px) 0;
		}

		.has-no-header-image & {
			display: none;
		}
	}

	.dropdown.is-quick-links {
		position: relative;
		z-index: 1;
		margin-top: rem(64px);
	}

	.round-text {
		position: absolute;
		z-index: -1;
		bottom: rem(-64px);
		left: rem(-304px);

		@include create-breakpoint(l) {
			left: rem(-128px);
		}

		@include create-breakpoint(ll) {
			z-index: 0;
			bottom: rem(-432px);
			left: rem(-304px);
		}

		@include create-breakpoint(ds) {
			left: rem(-160px);
		}

		@include create-breakpoint(d) {
			left: rem(-128px);
		}
	}

	&.has-no-image {
		display: block;
		background: $color-primary;
		min-height: rem(388px);
		padding: rem(140px) 0 rem(64px);

		.title {
			color: $color-tritary;
			font-weight: 300;
		}

		.title.is-big {
			color: #fff;
			font-weight: 400;
		}

		.header__content-container.has-logo {
			max-width: rem(2142px);
			width: 100% !important;
			padding: 0 rem(20px);
			left: 50%;
			transform: translateX(-50%);
		}

		.header__content-container {

			@include create-breakpoint(ll) {
				width: calc(37.5vw);
			}
		}

		.header__dropdown-container {
			padding: 0 20px;

			@include create-breakpoint(l) {
				display: flex;
				align-items: center;
				height: 100%;
			}
		}

		.dropdown.is-quick-links {
			margin-top: 0;
		}

		.dropdown.is-quick-links {
			@extend %dropdown-is-quick-link-tritary;
		}

		.round-text {
			z-index: -1;
			display: none;

			@include create-breakpoint(ll) {
				display: block;
				bottom: -100%;
			}
		}

		&.is-vacancy-overview {

			.title p {
				display: inline;
			}
		}

		&.has-no-dropdown {

			.header__content-container {
				width: auto;
				justify-content: initial;
			}

			.header__content {
				max-width: rem(826px);
				padding: 0;
			}

			.container {
				max-width: rem(2142px);
			}
		}

		&.has-dropdown {

			.container {
				max-width: rem(2142px);
			}

			.header__content-container {
				display: block;
			}

			.header__content {
				max-width: initial;
			}
		}
	}
}