@use '../mixins' as *;

@font-face {
	font-family: 'GT Walsheim';
	src: url('../../fonts/GT-Walsheim-Regular.woff2') format('woff2'), url('../../fonts/GT-Walsheim-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'GT Walsheim';
	src: url('../../fonts/GT-Walsheim-Light.woff2') format('woff2'), url('../../fonts/GT-Walsheim-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'GT Walsheim';
	src: url('../../fonts/GT-Walsheim-Light-Oblique.woff2') format('woff2'), url('../../fonts/GT-Walsheim-Light-Oblique.woff') format('woff');
	font-weight: 300;
	font-style: oblique;
	font-stretch: normal;
	font-display: block;
}

$font-text: "GT Walsheim", sans-serif;

@mixin font-text {
	font-family: $font-text;
	font-size: rem(20px);
	line-height: rem(32px);
	font-weight: 300;

	@media only screen and (min-width: 432px) {
		font-size: rem(19px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(18px);
	}
}

@mixin font-caption {
	font-family: $font-text;
	font-size: rem(14px);
	line-height: rem(24px);
}

@mixin font-navigation-link {
	font-family: $font-text;
	font-size: rem(20px);
	line-height: rem(32px);
}

@mixin font-menu-link {
	font-family: $font-text;
	font-size: rem(32px);
	line-height: rem(38px);
	font-weight: 400;
}

@mixin font-menu-text {
	font-family: $font-text;
	font-size: rem(24px);
	line-height: rem(40px);
	font-weight: 300;
}

@mixin font-text-bigger {
	font-family: $font-text;
	font-size: rem(24px);
	line-height: rem(32px);
}

@mixin font-list {
	font-family: $font-text;
	font-size: rem(32px);
	line-height: rem(38px);
}

@mixin font-title {
	font-family: $font-text;
	font-size: rem(40px);
	line-height: rem(48px);
}

@mixin font-title-industry {
	font-family: $font-text;
	font-size: rem(48px);
	line-height: rem(48px);
}

@mixin font-title-med {
	font-family: $font-text;
	font-size: rem(52px);
	line-height: rem(65px);

	@include create-breakpoint(l) {
		font-size: rem(64px);
		line-height: rem(80px);
	}
}

@mixin font-title-big {
	font-family: $font-text;
	font-size: rem(60px);
	line-height: rem(60px);

	@include create-breakpoint(t) {
		font-size: rem(70px);
		line-height: rem(70px);
	}

	@include create-breakpoint(l) {
		font-size: rem(80px);
		line-height: rem(80px);
	}
}