﻿@use '../custom/' as *;
@use '../mixins' as *;

.banner-image {
	position: relative;
	display: block;
	min-height: rem(662px);
	overflow: hidden;
	border-radius: rem(28px);

	img {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
		transform: scale(1);
		transition: transform $transition-time ease;
	}

	&:hover,
	&:focus {

		img {
			transform: scale(1.05);
		}
	}
}