﻿@use '../custom' as *;
@use '../mixins' as *;

.home-shortcuts-block {
	background: $color-primary url(../../images/bubble-background/bubble-background-432.jpg) center bottom no-repeat;
	padding: rem(60px) 0;

	@include create-breakpoint(ml) {
		background-image: url(../../images/bubble-background/bubble-background-768.jpg);
	}

	@include create-breakpoint(t) {
		padding: rem(80px) 0;
		background-image: url(../../images/bubble-background/bubble-background-1024.jpg);
	}

	@include create-breakpoint(l) {
		padding: rem(120px) 0;
		background-image: url(../../images/bubble-background/bubble-background-1440.jpg);
	}

	@include create-breakpoint(ll) {
		background-image: url(../../images/bubble-background/bubble-background-1920.jpg);
	}

	@include create-breakpoint(d) {
		background-image: url(../../images/bubble-background/bubble-background-2560.jpg);
	}

	.container {
		position: relative;
		max-width: rem(1056px);
		min-height: rem(1900px);

		@include create-breakpoint(t) {
			min-height: rem(1556px);
		}

		@include create-breakpoint(l) {
			min-height: rem(938px);
		}
	}

	.shortcut {
		position: absolute;
		overflow: hidden;
		border-radius: 50%;
		transition: all $transition-time ease-in-out;

		.shortcut__title {
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			font-weight: normal;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
			object-position: center;
			transition: transform $transition-time ease-in-out;
		}

		&:hover {

			img {
				transform: translate(-50%, -50%) scale(1.1);
			}
		}

		&.has-animation {

			.js & {
				opacity: 0;

				&.animate {
					opacity: 1;
				}
			}
		}
	}

	.shortcut.number-1,
	.shortcut.number-2,
	.shortcut.number-3 {

		.shortcut__title {
			@include font-title;
			color: #fff;
			text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
		}
	}

	.shortcut.number-1 {
		width: rem(496px);
		height: rem(496px);
		margin-left: rem(-248px);
		top: rem(20px);
		left: 50%;

		@include create-breakpoint(l) {
			margin: 0;
			top: rem(20px);
			left: rem(30px);
		}
	}

	.shortcut.number-2 {
		width: rem(356px);
		height: rem(356px);
		margin-left: rem(-178px);
		top: rem(760px);
		left: 50%;

		@include create-breakpoint(t) {
			top: rem(530px);
		}

		@include create-breakpoint(l) {
			margin: 0;
			top: rem(102px);
			left: rem(536px);
		}
	}

	.shortcut.number-3 {
		width: rem(424px);
		height: rem(424px);
		margin-left: rem(-212px);
		top: rem(1464px);
		left: rem(50%);

		@include create-breakpoint(t) {
			top: rem(1164px);
		}

		@include create-breakpoint(l) {
			margin: 0;
			top: rem(440px);
			left: rem(348px);
		}
	}

	.shortcut.number-4 {
		width: rem(240px);
		height: rem(240px);
		margin-left: rem(-232px);
		top: rem(1120px);
		left: 50%;
		background: $color-yellow;
		color: $color-primary;

		@include create-breakpoint(t) {
			margin-left: rem(-264px);
			top: rem(920px);
		}

		@include create-breakpoint(l) {
			margin: 0;
			top: rem(536px);
			left: rem(92px);
		}

		&:hover {
			background: lighten($color-yellow, 10%);
		}
	}

	.shortcut.number-5 {
		width: rem(240px);
		height: rem(240px);
		background: $color-news-list;
		color: $color-primary;
		top: rem(1200px);
		left: 50%;

		@include create-breakpoint(t) {
			top: rem(888px);
		}

		@include create-breakpoint(l) {
			margin: 0;
			top: rem(416px);
			left: rem(768px);
		}

		&:hover {
			background: lighten($color-news-list, 10%);
		}
	}

	.shortcut.number-6 {
		width: rem(160px);
		height: rem(160px);
		color: $color-primary;
		background: $color-tritary;
		margin-left: rem(-200px);
		top: rem(507px);
		left: 50%;

		@include create-breakpoint(t) {
			margin-left: rem(-300px);
			top: rem(457px);
		}

		@include create-breakpoint(l) {
			margin: 0;
			top: rem(762px);
			left: rem(240px);
		}

		&:hover {
			background: lighten($color-tritary, 10%);
		}
	}

	.shortcut.number-7 {
		width: rem(200px);
		height: rem(200px);
		color: #fff;
		background: $color-text;
		margin-left: 0;
		top: rem(558px);
		left: 50%;

		@include create-breakpoint(t) {
			margin-left: rem(140px);
			top: rem(448px);
		}

		@include create-breakpoint(l) {
			margin: 0;
			top: rem(674px);
			left: rem(770px);
		}

		&:hover {
			background: lighten($color-text, 10%);
		}
	}
}
