﻿@use "sass:math";
@use "../mixins/breakpoints";

// Gutter creation mixin
@mixin create-columns-gutter($size, $breakpoint: "0") {
	$size-half: (math.div($size, 2) * 1px);

	@if($breakpoint == "0") {

		.columns.has-gutter-#{$size} {
			margin: ($size-half * -1);

			> .column {
				border-color: transparent;
				border-style: solid;
				border-top-width: ($size-half);
				border-right-width: ($size-half);
				border-bottom-width: ($size-half);
				border-left-width: ($size-half);
			}
		}
	}
	@else {

		@include breakpoints.create-breakpoint($breakpoint) {

			.columns.has-gutter-#{$size}-#{$breakpoint} {
				margin: ($size-half * -1);

				> .column {
					border-color: transparent;
					border-style: solid;
					border-top-width: ($size-half);
					border-right-width: ($size-half);
					border-bottom-width: ($size-half);
					border-left-width: ($size-half);
				}
			}
		}
	}
}

// Columns
.columns {
	display: flex;
	flex-wrap: wrap;

	&.has-no-wrap {
		flex-wrap: nowrap;
	}

	&.is-horizontaly-aligned {
		justify-content: center;
	}

	&.is-vertical-aligned {
		align-items: center;
	}
}

.column {
	flex: 0 0 auto;
	width: 100%;
}

@mixin create-columns-breakpoint($size-name, $size, $breakpoint) {

	@if($breakpoint == "b") {

		.columns.is-#{$size-name} {

			> .column {
				width: $size;
			}
		}
	}
	@else {

		.columns.is-#{$size-name}-#{$breakpoint} {

			> .column {
				@include breakpoints.create-breakpoint($breakpoint) {
					width: $size;
				}
			}
		}
	}
}

@mixin create-column-breakpoint($size-name, $size, $breakpoint) {

	@if($breakpoint == "b") {

		.column.is-#{$size-name} {
			width: $size;
		}
	}
	@else {

		.column.is-#{$size-name}-#{$breakpoint} {

			@include breakpoints.create-breakpoint($breakpoint) {
				width: $size;
			}
		}
	}
}
