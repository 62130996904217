﻿@use '../mixins' as *;
@use '../custom' as *;
@use 'link' as *;

.content {

	p,
	ol,
	ul,
	table {
		margin: 0 0 rem(30px);

		a {
			@extend %link;
			@extend %link-small;
		}
	}

	ul,
	ol {
		padding-left: rem(20px);
	}

	&.is-bigger {
		@include font-text-bigger;

		a {
			@extend %link;
		}
	}
	/*	ul:not(.social-media) {
		list-style: none;
		border-top: 1px solid rgba($color-text, 0.2);
		padding: 0;

		li {
			position: relative;
			padding: rem(8px) 0 rem(7px) rem(24px);
			border-bottom: 1px solid rgba($color-text, 0.2);

			&:before {
				position: absolute;
				content: '';
				width: rem(6px);
				height: rem(6px);
				top: rem(22px);
				left: 0;
				background: $color-text;
				border-radius: 50%;
			}
		}
	}

	table {
		width: 100%;

		td {
			display: block;
			vertical-align: top;

			@include create-breakpoint(ml) {
				display: table-cell;
				padding-right: rem(20px);
			}

			&:last-of-type {
				padding-right: 0;
			}
		}

		&.product-properties {
			border-collapse: collapse;

			td {
				padding: rem(10px) rem(20px);
			}

			tr:nth-child(odd) td {
				background: #f3f5f9;
			}

			td:first-of-type {
				font-weight: bold;
				padding-right: 0;
			}
		}

		&.contact-information {
			max-width: rem(672px);
			margin: 0 auto;
			text-align: left;

			tr:first-of-type td {
				font-size: rem(24px);
				font-weight: bold;
			}
		}
	}

	.contact-information-table {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		width: 100%;

		table {
			margin: 0 0 rem(24px);
		}

		@media only screen and (min-width: 520px) {
			flex-wrap: initial;
			max-width: rem(672px);
			margin: 0 auto;
		}
	}

	&.is-centerd {
		text-align: center;
	}

	&.is-smaller {
		@include font-text-smaller;
	}

	.sub-title {
		@include font-h3;
		margin: 0 0 rem(10px);
	}

	+ .buttons {
		margin-top: rem(35px);
	}

	.social-media {
		@extend %rouded-icons;
		padding: 0;
		margin-top: rem(36px);
	}*/
}

