﻿@use '../custom' as *;
@use '../mixins' as *;

.contact-information {
	@include font-text-bigger;
	padding: 0;
	list-style: none;
	color: $color-primary;

	.contact-information__item {
		display: block;
		position: relative;
		padding: 0 0 0 rem(48px);
		margin: 0 0 rem(8px);

		span {
			@include underline-effect-base($color-primary);
		}

		&:after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
		}

		&.is-telephone:after {
			@include icon($icon-phone);
		}

		&.is-email:after {
			@include icon($icon-mail);
		}

		&.is-whatss-app:after {
			@include icon($icon-whatss-app);
		}

		&:hover,
		&:focus {

			span {
				@include underline-effect-hover();
			}
		}
	}
}