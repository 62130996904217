﻿@use '../custom/' as *;
@use '../mixins' as *;

.filters {
	background: $color-tritary;
	display: flex;
	align-items: center;
	padding: rem(64px);
	height: 100%;
	border-radius: rem(28px);

	.title {
		position: relative;
		color: $color-primary;
		margin: 0 0 rem(18px);
		padding: 0 0 0 rem(40px);

		&:before {
			@include icon($icon-filter);
			position: absolute;
			top: rem(14px);
			left: 0;
			font-size: rem(24px);
		}
	}

	.filter__checkbox {
		display: none;

		&:checked + .filter__label {

			&:before {
				@include icon($icon-check);
			}
		}
	}

	.filter__label {
		position: relative;
		display: block;
		padding: rem(13px) 0 rem(13px) rem(42px);
		border-bottom: 1px solid rgba($color-text, 0.1);
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			width: rem(24px);
			height: rem(24px);
			padding-top: rem(5px);
			border: 1px solid rgba($color-text, 0.1);
			border-radius: rem(6px);
			bottom: rem(19px);
			left: 0;
			font-size: rem(14px);
			text-align: center;
			background: #fff;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}

	.button {
		margin-top: rem(32px);

		.js & {
			display: none;
		}
	}
}