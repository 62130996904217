﻿@use '../mixins' as *;
@use '../custom/fonts' as *;
@use '../custom/variables' as *;

.title {
	@include font-title;
	position: relative;
	font-weight: normal;

	&.is-big {
		@include font-title-big;
	}

	&.is-med {
		@include font-title-med;
	}

	strong {
		color: $color-tritary;
		font-weight: normal;
	}

	.vacancies-count {
		position: relative;
		top: rem(-12px);
	}

	span {
		display: block;
	}
}

