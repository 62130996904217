﻿@use '../custom' as *;
@use '../mixins/rem' as *;
@use '../mixins/breakpoints' as *;

.round-text {
	width: rem(608px);
	height: rem(608px);
	animation: spin 50s linear infinite;
	background-size: cover;

	&.is-colleague-talking {

		&.loaded {
			background-image: url('../../images/circle text/client-talking-purple.svg');
		}
	}

	&.is-our-goal {
		width: rem(608px);
		height: rem(608px);

		&.loaded {
			background-image: url('../../images/circle text/our-goal-yellow.svg');
		}

		@include create-breakpoint(t) {
			width: rem(760px);
			height: rem(760px);
		}
	}

	&.is-tell-me-the-benefits {
		width: rem(460px);
		height: rem(460px);

		&.loaded {
			background-image: url('../../images/circle text/tell-me-the-benefits-purple.svg');
		}

		@include create-breakpoint(t) {
			width: rem(608px);
			height: rem(608px);
		}
	}

	&.is-our-process {

		&.loaded {
			background-image: url('../../images/circle text/our-process-green.svg');
		}
	}

	&.is-our-machine {

		&.loaded {
			background-image: url('../../images/circle text/our-machine-green.svg');
		}
	}

	&.is-our-community {

		&.loaded {
			background-image: url('../../images/circle text/our-community-yellow.svg');
		}
	}

	&.is-our-news-is-good {

		&.loaded {
			background-image: url('../../images/circle text/our-news-is-good-news-purple.svg');
		}
	}

	&.is-digesting-with-style {

		&.loaded {
			background-image: url('../../images/circle text/digesting-with-style-natural.svg');
		}
	}
}

@keyframes spin {

	100% {
		transform: rotate(360deg);
	}
}
