﻿@use '../custom' as *;
@use '../mixins' as *;

.dropdown-checkbox {
	display: none;

	+ .dropdown {
		margin: 0;

		label {
			cursor: pointer;
		}

		dd {
			overflow: hidden;
			transition: height $transition-time ease;
			//margin: 0 0 rem(41px);
		}
	}

	&:checked + .dropdown {

		dd {
			height: 100%;
		}

		&.is-faq {

			label {

				&:before {
					@include icon($icon-minus);
				}
			}
		}

		&.is-quick-links {
			background: #fff;
			color: $color-text;
			border-radius: rem(28px) rem(28px) 0 0;

			label {

				&:after {
					@include icon($icon-arrow-top);
				}
			}

			dd {
				background: #fff;
			}
		}

		&.is-category-description {

			label {
				color: $color-primary;
			}
		}
	}

	&:not(:checked) + .dropdown {

		dd {
			height: 0 !important;
		}
	}
}

.dropdown.is-faq {
	background: $color-news-list;
	border-radius: rem(32px);
	margin: 0 0 rem(16px);

	label {
		@include font-text-bigger;
		line-height: rem(32px);
		position: relative;
		display: block;
		padding: rem(16px) rem(20px) rem(16px) rem(50px);
		cursor: pointer;
		color: $color-primary;

		@include create-breakpoint(ml) {
			padding: rem(16px) rem(20px) rem(16px) rem(90px);
		}

		&:before {
			@include icon($icon-plus);
			position: absolute;
			top: rem(16px);
			left: rem(12px);
			font-size: rem(32px);
			color: $color-primary;

			@include create-breakpoint(ml) {
				left: rem(40px);
			}
		}
	}

	dd {
		position: relative;
		margin: 0;
	}

	.dropdown__content {
		position: relative;
		padding: rem(36px) rem(20px) rem(6px) rem(20px);
		border-top: 1px solid rgba($color-text, 0.1);

		@include create-breakpoint(t) {
			padding: rem(36px) rem(48px) rem(6px) rem(48px);
		}
	}
}

.dropdown.is-quick-links {
	@include font-text-bigger;
	position: relative;
	border-radius: rem(28px);
	background: $color-primary;
	color: #fff;
	width: max-content;
	min-width: rem(356px);

	label {
		position: relative;
		z-index: 1;
		display: block;
		padding: rem(16px) rem(42px);

		&:after {
			@include icon($icon-arrow-bottom);
			position: absolute;
			top: rem(20px);
			right: rem(24px);
			font-size: rem(24px);
		}
	}

	dd {
		position: absolute;
		width: 100%;
		padding: 0;
		border-radius: 0 0 rem(28px) rem(28px);
		margin: rem(-32px) 0 0;
		left: 0;
		background: $color-primary;
	}

	ul {
		list-style: none;
		padding: rem(32px) 0 0;
	}

	a {
		display: block;
		padding: rem(16px) rem(42px);
		border-top: 1px solid rgba($color-primary, 0.1);

		span {
			@include underline-effect-base($color-text, $bottom: 0, $height: 1px);
		}

		&:hover span {
			@include underline-effect-hover();
		}
	}
}

%dropdown-is-quick-link-tritary {
	background: $color-tritary;
	color: $color-primary;

	dd {
		background: $color-tritary;
	}
}

.dropdown.is-category-description {
	border-bottom: 1px solid rgba($color-text, 0.1);

	label {
		@include font-title;
		display: block;
		padding: rem(16px) rem(32px);
	}

	dd {
		margin: 0;
	}

	.dropdown__content {
		padding: 0 rem(32px) rem(16px);
	}

	.content {

		p:last-child {
			margin: 0;
		}
	}

	&:last-of-type {
		border-bottom: 0; 
	}
}