﻿@use '../custom' as *;
@use '../mixins' as *;

.header-navigation {
	display: flex;
	align-items: center;
	padding: rem(18px) rem(20px) rem(18px) rem(20px);

	.has-no-overflow & {
		padding: rem(18px) calc(1.25rem + var(--sw)) rem(18px) rem(20px);
	}

	@include create-breakpoint(l) {
		padding: rem(18px) rem(272px) rem(18px) rem(20px);

		.has-no-overflow & {
			padding: rem(18px) calc(17rem + var(--sw)) rem(18px) rem(20px);
		}
	}

	.hamburger {
		position: fixed;
		right: 0;
		bottom: 0;

		.has-no-overflow & {
			right: var(--sw);
		}

		@include create-breakpoint(l) {
			top: rem(48px);
			right: rem(32px);
			bottom: 0;

			.has-no-overflow & {
				right: calc(2rem + var(--sw));
			}
		}
	}

	.header-navigation__link {
		@include underline-effect-base(#fff);
		@include font-text-bigger;
		color: #fff;
		transition: opacity $transition-time ease;
		animation: fade-in 1s ease 0s both;

		&:hover {
			@include underline-effect-hover();
		}

		.home &,
		.page-product &,
		.has-no-header-image & {
			color: #fff;

			&:before {
				background: #fff;
			}
		}
	}

	.has-no-overflow & {

		.header-navigation__link {
			opacity: 0;
			display: none;
		}
	}
}