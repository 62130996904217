﻿@use '../custom' as *;
@use '../mixins' as *;

.button {
	@include font-text-bigger;
	position: relative;
	z-index: 0;
	display: inline-flex;
	padding: rem(16px) rem(90px) rem(16px) rem(42px);
	transition: color $transition-time ease-in-out;
	-webkit-appearance: none;
	cursor: pointer;
	text-decoration: none;
	background-color: $color-secondary;
	color: #fff;
	border: none;
	overflow: hidden;
	border-radius: rem(32px);
	white-space: nowrap;
	font-weight: 400;

	&:before {
		@include icon($icon-arrow-right);
		color: inherit;
		position: absolute;
		z-index: 1;
		top: 50%;
		right: rem(40px);
		font-size: rem(24px);
		transform: translateY(-50%);
	}

	&:after {
		position: absolute;
		z-index: -2;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(#fff, 0.2);
		transform: scaleX(0);
		transition: transform 0.3s ease;
		transform-origin: left;
	}

	&:hover,
	&:focus {

		&:after {
			transform: scaleX(1);
		}
	}


	&.has-no-arrow {
		padding-right: 32px;

		&:before {
			display: none;
		}
	}

	&.is-loading {
		padding-right: rem(46px);
		transition: none;

		&:before {
			position: absolute;
			display: block;
			z-index: 1;
			content: '';
			border: rem(4px) solid rgba(#fff, 0.5);
			border-radius: 50%;
			border-top: rem(4px) solid #fff;
			width: rem(20px);
			height: rem(20px);
			top: rem(22px);
			right: rem(16px);
			left: auto;
			transition: none;
			transform-origin: center;
			transform: none;
			animation: spin 1.5s linear infinite;
			opacity: 1;
			background: none;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	&.is-tritary {
		background: $color-tritary;
		color: $color-primary;
	}
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	margin: rem(-10px) rem(-10px);

	.button {
		margin: rem(10px) rem(10px);
	}

	&.is-centerd {
		justify-content: center;
	}

	&.is-alignd-right {

		@include create-breakpoint(l) {
			justify-content: flex-end;
		}
	}
}
