﻿@use '../custom' as *;
@use '../mixins/rem' as *;
@use '../mixins/breakpoints' as *;

.header-product {
	position: relative;

	.container {
		position: relative;
	}

	.title {
		margin: 0 0 rem(38px);
	}

	.header-product__intro {
		max-width: rem(636px);
		padding: rem(200px) 0 0;
		opacity: 0;

		.navigation-links {
			margin: rem(80px) 0 0;

			@include create-breakpoint(t) {
				margin: rem(120px) 0 0;
			}

			@include create-breakpoint(l) {
				margin: rem(150px) 0 0;
			}
		}
	}

	.header-product__image {
		position: relative;
		display: block;
		width: 100%;
		max-width: rem(600px);
		height: auto;
		margin: rem(120px) auto 0;
		opacity: 0;

		@include create-breakpoint(t) {
			margin: rem(60px) auto 0;
		}

		@include create-breakpoint(l) {
			max-width: rem(600px);
			margin: rem(-262px) 0 0 auto;
		}

		@include create-breakpoint(ll) {
			max-width: rem(800px);
			margin: rem(-362px) 0 0 auto;
		}

		@include create-breakpoint(ds) {
			max-width: rem(1056px);
			margin: rem(-432px) 0 0 auto;
		}
	}

	.header-product__title {
		max-width: rem(1056px);
		padding: rem(100px) 0 0;
		margin: 0 0 0 auto;

		@include create-breakpoint(t) {
			padding: rem(150px) 0 0;
		}

		@include create-breakpoint(l) {
			padding: rem(200px) 0 0;
		}
	}

	.round-text {
		display: none;
		z-index: -1;
		position: absolute;
		width: rem(408px);
		height: rem(408px);
		left: rem(-240px);
		bottom: 0;

		@include create-breakpoint(lm) {
			display: block;
		}

		@include create-breakpoint(ll) {
			width: rem(608px);
			height: rem(608px);
			left: rem(-256px);
		}

		@include create-breakpoint(ds) {
			left: rem(-128px);
		}
	}

	&.animate {

		.header-product__intro {
			animation: fade-in 0.3s ease both;
		}

		.header-product__image {
			animation: fade-in 0.3s ease 0.2s both;
		}
	}

	.header-product__logo {
		position: absolute;
		z-index: 1;
		top: rem(48px);
		left: rem(20px);
	}
}
