﻿@use '../custom' as *;
@use '../mixins/rem' as *;
@use '../mixins/breakpoints' as *;

.header-home {
	position: relative;
	z-index: 0;
	height: 100vh;
	background: $color-primary;
	overflow: hidden;

	&:hover {
		cursor: url(../../images/scroll-down.png), auto;
	}

	h1 {
		position: absolute;
		color: #fff;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.js & {
		height: var(--vh);
	}

	.header-home__navigation {
		position: absolute;
		left: rem(20px);
		bottom: rem(64px);
		z-index: 5;
		opacity: 0;
		animation: fade-in 1s ease 1s both;

		@include create-breakpoint(ll) {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 37.5%;
			left: 0;
		}
	}

	.navigation-links {

		@include create-breakpoint(ll) {
			width: 100%;
			max-width: rem(560px);
			padding: 0 rem(10px);
		}
	}

	.video {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: contain;
		object-position: center;
		opacity: 0;
		animation: fade-in 1s ease 0.5s both;

		&.is-mobile {
			display: block;

			@include create-breakpoint(l) {
				display: none;
			}
		}

		&.is-desktop {
			display: none;

			@include create-breakpoint(l) {
				display: block;
			}
		}
	}

	&.has-video {

		h1 {
			opacity: 0;
		}
	}
}