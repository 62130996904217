﻿@use '../custom' as *;
@use '../mixins' as *;

.summary {
	background: $color-tritary;
	margin: rem(140px) 0 0;
	padding: rem(116px) 0 rem(100px);

	@include create-breakpoint(t) {
		padding: rem(166px) 0 rem(150px);
		margin: rem(190px) 0 0;
	}

	@include create-breakpoint(l) {
		padding: rem(216px) 0 rem(200px);
		margin: rem(240px) 0 0;
	}

	.title {
		color: $color-primary;
		margin: 0 0 rem(32px);
	}

	+ .call-to-action {
		margin: 0;
		background: $color-tritary;
	}
}