﻿@use '../mixins' as *;
@use '../custom' as *;
@use 'link' as *;

.vacancies-count {
	@include font-navigation-link;
	display: inline-block;
	width: rem(32px);
	height: rem(32px);
	border-radius: 50%;
	line-height: rem(32px);
	text-align: center;
	background: $color-tritary;
	color: $color-primary;

	.header.has-image & {
		background: $color-primary;
		color: $color-tritary;
	}
}