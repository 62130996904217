﻿@use '../mixins/rem' as *;

.container {
	display: flow-root;
	width: 100%;
	max-width: rem(1656px);
	padding: 0 rem(20px);
	margin: 0 auto;

	&.is-small {
		max-width: rem(816px);
	}

	&.is-small-med {
		max-width: rem(1378px);
	}

	&.is-med {
		max-width: rem(1224px);
	}
}