﻿@use '../custom' as *;
@use '../mixins' as *;

.call-to-action {
	position: relative;
	color: #fff;
	margin-top: rem(60px);

	@include create-breakpoint(t) {
		margin-top: rem(80px);
	}

	@include create-breakpoint(l) {
		margin-top: rem(120px);
	}

	.call-to-action__content-container .container {
		z-index: 3;
		max-width: rem(1096px);
		padding-top: rem(60px);
		padding-bottom: rem(60px);

		@include create-breakpoint(t) {
			padding-top: rem(80px);
			padding-bottom: rem(80px);
		}

		@include create-breakpoint(l) {
			padding-top: rem(120px);
			padding-bottom: rem(120px);
		}
	}

	.call-to-action__content-container {
		display: flex;
		position: relative;
		z-index: 2;
		flex-wrap: wrap;
		align-items: flex-end;
		min-height: rem(604px);
		overflow: hidden;

		&:before,
		&:after {
			position: absolute;
			content: '';
			width: calc(100% - 2.5rem);
			height: 100%;
			top: 0;
			left: rem(20px);
			border-radius: rem(28px);
		}

		&:before {
			z-index: -1;
			//background: $color-primary;
			//opacity: 0.4;
		}

		&:after {
			z-index: -2;
			background: linear-gradient(90deg, rgba(80,1,1,1) 0%, rgba(255,122,0,1) 100%);
			opacity: 0.4;
		}
	}

	.call-to-action__image {
		position: absolute;
		z-index: -3;
		width: calc(100% - 2.5rem);
		height: 100%;
		top: 0;
		left: rem(20px);
		object-fit: cover;
		object-position: center;
		border-radius: rem(28px);
	}

	.title {
		margin: 0 0 rem(70px);
	}

	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: rem(240px);
		bottom: 0;
		left: 0;
		background: $color-primary;
	}
}