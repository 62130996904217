﻿@use '../custom' as *;
@use '../mixins' as *;
@use 'swiper/css';
@use 'swiper/css/navigation';

.custom-swiper {

	.swiper-button-prev,
	.swiper-button-next {
		width: rem(66px);
		height: rem(90px);
		top: auto;
		bottom: rem(-32px);
		background-color: $color-secondary;
		color: #fff;

		@include create-breakpoint(t) {
			width: rem(88px);
			height: rem(120px);
		}

		@include create-breakpoint(l) {
			width: rem(66px);
			height: rem(90px);
		}

		@include create-breakpoint(ll) {
			width: rem(88px);
			height: rem(120px);
		}

		&.swiper-button-disabled {
			opacity: 1;
			color: rgba(#fff, 25%);
		}

		&:after {
			font-size: rem(33px);
		}
	}

	.swiper-button-prev {
		position: absolute;
		left: 50%;
		border-radius: rem(28px) 0 0 rem(28px);
		transform: translateX(-100%);

		@include create-breakpoint(l) {
			left: rem(-32px);
			transform: none;
		}

		&:after {
			@include icon($icon-arrow-bold-left);
		}
	}

	.swiper-button-next {
		left: 50%;
		border-radius: 0 rem(28px) rem(28px) 0;
		transform: translateX(-1px);

		@include create-breakpoint(l) {
			margin: 0;
			left: rem(34px);
			transform: none;
		}

		@include create-breakpoint(ll) {
			left: rem(56px);
		}

		&:after {
			@include icon($icon-arrow-bold-right);
		}
	}

	&.custom-swiper-small {

		.swiper-button-prev,
		.swiper-button-next {
			position: static;
			width: rem(64px);
			height: rem(64px);
			border-radius: 50%;
			margin: 0;
			top: rem(58px);
			background-color: $color-secondary;
			color: #fff;
			transform: none;

			&:after {
				font-size: rem(21px);
			}

			&.swiper-button-disabled {
				opacity: 0.2;
			}
		}

		.swiper-button-prev {
			/*@include create-breakpoint(l) {
					left: rem(-42px);
				}*/

			&:after {
				@include icon($icon-arrow-bold-left);
			}
		}

		.swiper-button-next {
			/*@include create-breakpoint(l) {
					right: rem(96px);
				}*/

			&:after {
				@include icon($icon-arrow-bold-right);
			}
		}

		.swiper-pagination {
			position: absolute;
			width: 100%;
			height: rem(4px);
			max-width: rem(496px);
			border-radius: rem(2px);
			bottom: 1px;
			left: 50%;
			transform: translateX(-50%);
			background: rgba($color-secondary, 0.2);

			.swiper-pagination-progressbar-fill {
				display: block;
				position: absolute;
				width: 100%;
				height: rem(8px);
				border-radius: rem(4px);
				top: rem(-2px);
				left: 0;
				background: $color-secondary;
				transform-origin: left;
			}
		}
	}
}

