﻿@use '../custom/fonts' as *;
@use '../custom/variables' as *;
@use '../mixins/rem' as *;

.check-list {
	@include font-text-bigger;
	list-style: none;

	li {
		position: relative;
		padding: rem(16px) 0 rem(16px) rem(50px);
		border-bottom: 1px solid rgba($color-text, 0.1);

		&:before {
			@include icon($icon-check);
			position: absolute;
			top: rem(19px);
			left: 0;
			font-size: rem(24px);
			color: $color-primary;
		}

		&:last-child {
			border-bottom: none;
		}
	}
}