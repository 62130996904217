$icon-apple-core: '\ea01';
$icon-arrow-bold-down: '\ea02';
$icon-arrow-bold-left: '\ea03';
$icon-arrow-bold-right: '\ea04';
$icon-arrow-bold-up: '\ea05';
$icon-arrow-bottom: '\ea06';
$icon-arrow-left: '\ea07';
$icon-arrow-right: '\ea08';
$icon-arrow-top: '\ea09';
$icon-brain: '\ea0a';
$icon-check: '\ea0b';
$icon-chewing: '\ea0c';
$icon-close: '\ea0d';
$icon-cloud: '\ea0e';
$icon-cog: '\ea0f';
$icon-digesting: '\ea10';
$icon-directions: '\ea11';
$icon-drop: '\ea12';
$icon-facebook: '\ea13';
$icon-filter: '\ea14';
$icon-flame: '\ea15';
$icon-food: '\ea16';
$icon-globe: '\ea17';
$icon-instagram: '\ea18';
$icon-inwards: '\ea19';
$icon-light-bulb: '\ea1a';
$icon-linkedin: '\ea1b';
$icon-location: '\ea1c';
$icon-mail: '\ea1d';
$icon-minus: '\ea1e';
$icon-phone: '\ea1f';
$icon-pinterest: '\ea20';
$icon-plant: '\ea21';
$icon-play: '\ea22';
$icon-plus: '\ea23';
$icon-pot: '\ea24';
$icon-power: '\ea25';
$icon-product-step: '\ea26';
$icon-recycle: '\ea27';
$icon-telephone: '\ea28';
$icon-temperature: '\ea29';
$icon-twitter: '\ea2a';
$icon-vimeo: '\ea2b';
$icon-waste: '\ea2c';
$icon-whatss-app: '\ea2d';
$icon-youtube: '\ea2e';

@at-root {
	@font-face {
		font-family: icon;
		
		src: url('../../fonts/icon.woff2') format('woff2'), url('../../fonts/icon.woff') format('woff'); 
		font-style: normal;
		font-weight: normal;
		font-display: block;
	}
}

@mixin icon($content: none) {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: icon;
	line-height: 1;
	text-transform: none;
	vertical-align: middle;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon:before {
	@include icon;
}

.icon-apple-core:before { content: $icon-apple-core; }
.icon-arrow-bold-down:before { content: $icon-arrow-bold-down; }
.icon-arrow-bold-left:before { content: $icon-arrow-bold-left; }
.icon-arrow-bold-right:before { content: $icon-arrow-bold-right; }
.icon-arrow-bold-up:before { content: $icon-arrow-bold-up; }
.icon-arrow-bottom:before { content: $icon-arrow-bottom; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-arrow-top:before { content: $icon-arrow-top; }
.icon-brain:before { content: $icon-brain; }
.icon-check:before { content: $icon-check; }
.icon-chewing:before { content: $icon-chewing; }
.icon-close:before { content: $icon-close; }
.icon-cloud:before { content: $icon-cloud; }
.icon-cog:before { content: $icon-cog; }
.icon-digesting:before { content: $icon-digesting; }
.icon-directions:before { content: $icon-directions; }
.icon-drop:before { content: $icon-drop; }
.icon-facebook:before { content: $icon-facebook; }
.icon-filter:before { content: $icon-filter; }
.icon-flame:before { content: $icon-flame; }
.icon-food:before { content: $icon-food; }
.icon-globe:before { content: $icon-globe; }
.icon-instagram:before { content: $icon-instagram; }
.icon-inwards:before { content: $icon-inwards; }
.icon-light-bulb:before { content: $icon-light-bulb; }
.icon-linkedin:before { content: $icon-linkedin; }
.icon-location:before { content: $icon-location; }
.icon-mail:before { content: $icon-mail; }
.icon-minus:before { content: $icon-minus; }
.icon-phone:before { content: $icon-phone; }
.icon-pinterest:before { content: $icon-pinterest; }
.icon-plant:before { content: $icon-plant; }
.icon-play:before { content: $icon-play; }
.icon-plus:before { content: $icon-plus; }
.icon-pot:before { content: $icon-pot; }
.icon-power:before { content: $icon-power; }
.icon-product-step:before { content: $icon-product-step; }
.icon-recycle:before { content: $icon-recycle; }
.icon-telephone:before { content: $icon-telephone; }
.icon-temperature:before { content: $icon-temperature; }
.icon-twitter:before { content: $icon-twitter; }
.icon-vimeo:before { content: $icon-vimeo; }
.icon-waste:before { content: $icon-waste; }
.icon-whatss-app:before { content: $icon-whatss-app; }
.icon-youtube:before { content: $icon-youtube; }
