﻿@use '../custom' as *;
@use '../mixins' as *;
@use 'social-media' as *;

.content-block {
	position: relative;
	z-index: 0;
	padding-top: rem(60px);

	@include create-breakpoint(t) {
		padding-top: rem(80px);
	}

	@include create-breakpoint(l) {
		padding-top: rem(120px);
	}

	.title + .content,
	.content + .buttons {
		margin-top: rem(32px);
	}

	.date {
		@include font-text;
		color: $color-primary;
	}

	.title + .content {
		padding-left: rem(16px);

		@include create-breakpoint(t) {
			padding-left: rem(32px);
		}
	}

	.title.is-big + .content {
		padding-left: rem(32px);

		@include create-breakpoint(t) {
			padding-left: rem(64px);
		}
	}

	&.has-image {

		.content-block__content {

			@include create-breakpoint(ll) {
				padding: 0 0 0 rem(140px);
			}
		}

		.media {
			margin-top: rem(60px);

			@include create-breakpoint(t) {
				margin-top: rem(80px);
			}

			@include create-breakpoint(l) {
				margin-top: rem(120px);
			}

			&:first-child {
				margin-top: 0;
			}
		}

		.media.is-portrait {
			max-width: rem(498px);
			margin-right: auto;
			margin-left: auto;
		}
	}

	&.is-image-with-small-text {

		img {
			border-radius: rem(28px);
		}

		.content-block__content {
			position: relative;
			z-index: 1;
			max-width: rem(636px);
			padding: rem(40px) rem(40px);
			border-radius: rem(28px);
			margin: rem(-20px) 0 0 auto;
			background: $color-light-yellow;
			box-sizing: border-box;
			font-weight: 300;
			opacity: 0;
			transform: scale(0);
			transition: opacity 0.2s ease-out, transform 0.2s ease-out;

			@include create-breakpoint(t) {
				padding: rem(60px) rem(60px);
				margin: rem(-80px) 0 0 auto;
			}

			@include create-breakpoint(l) {
				padding: rem(60px) rem(80px);
				margin: rem(-120px) 0 0 auto;
				right: rem(70px);
			}

			@include create-breakpoint(ll) {
				padding: rem(60px) rem(120px);
				margin: rem(-190px) 0 0 auto;
				right: rem(140px);
			}

			&:before,
			&:after {
				position: absolute;
				z-index: 2;
				content: '';
				background-size: cover;
				opacity: 0;
				transform: scale(0);
				transition: opacity 0.2s ease-out, transform 0.2s ease-out;
			}

			&:before {
				width: rem(123px);
				height: rem(144px);
				bottom: rem(-56px);
				right: rem(-32px);
				background-image: url(../../images/strawberry.png);
				transition-delay: 0.2s;

				@include create-breakpoint(l) {
					right: rem(-90px);
				}
			}

			&:after {
				width: rem(147px);
				height: rem(107px);
				top: rem(-64px);
				left: rem(-32px);
				background-image: url(../../images/hop.png);
				transition-delay: 0.3s;

				@include create-breakpoint(ts) {
					top: rem(-48px);
					left: rem(-92px);
				}

				@include create-breakpoint(t) {
					top: rem(60px);
				}

				@include create-breakpoint(ll) {
					left: rem(-56px);
				}
			}

			.content {
				@include font-text-bigger;

				strong {
					font-weight: 400;
				}
			}
		}

		.content-block__content.animate {
			transform: scale(1);
			opacity: 1;

			&:before,
			&:after {
				transform: scale(1);
				opacity: 1;
			}
		}
	}

	&.is-product-step {

		.title.is-big + .content {
			padding-left: 0;
		}

		.column.has-content {
			display: flex;
			align-items: center;
			opacity: 0;
			/*			&:before {
				position: absolute;
				z-index: -1;
				content: '';
				width: rem(1264px);
				height: rem(1264px);
				background: url(../../images/spot.png) no-repeat;
				background-size: cover;
				left: rem(-364px);
				pointer-events: none;
			}*/
		}

		.content-block__content {
			position: relative;
			padding: 0 0 0 rem(80px);
			margin: 0 auto;

			@include create-breakpoint(ml) {
				padding: 0 0 0 rem(100px);
			}

			@include create-breakpoint(t) {
				max-width: rem(498px);
				padding: 0;
			}

			@include create-breakpoint(l) {
				max-width: rem(658px);
				padding: 0 rem(48px) 0 rem(80px);
			}

			@include create-breakpoint(ll) {
				max-width: rem(497px);
				padding: 0;
			}
		}

		.content-block__image,
		.content-block__video {
			border-radius: 50%;
		}

		.content-block__video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		.column.has-image {
			position: relative;
			z-index: -1;
			opacity: 0;
			order: -1;
		}

		.number {
			position: absolute;
			z-index: 1;
			display: block;
			width: rem(120px);
			height: rem(120px);
			top: rem(56px);
			left: rem(56px);
			font-size: rem(80px);
			line-height: rem(120px);
			border-radius: 50%;
			text-align: center;
			background: $color-tritary;
			color: $color-primary;
		}

		.media {
			position: relative;
			right: 0;

			@include create-breakpoint(l) {
				right: rem(-70px);
			}

			.content-block__image:nth-of-type(2),
			picture:nth-of-type(2) .content-block__image {
				position: absolute;
				width: rem(200px);
				bottom: rem(-32px);
				left: 0;

				@include create-breakpoint(ml) {
					width: rem(300px);
					bottom: rem(-48px);
				}

				@include create-breakpoint(t) {
					width: rem(398px);
					bottom: rem(-64px);
				}

				@include create-breakpoint(l) {
					width: rem(300px);
					bottom: rem(-48px);
				}

				@include create-breakpoint(ll) {
					width: rem(398px);
					bottom: rem(-64px);
				}
			}

			&.is-square {
				overflow: visible;
			}
		}

		&:nth-child(even) {

			.column.has-image {

				@include create-breakpoint(l) {
					order: 0;
				}
			}

			.media:after {

				@include create-breakpoint(l) {
					@include icon($icon-product-step);
					z-index: -1;
					position: absolute;
					font-size: rem(928px);
					bottom: rem(-496px);
					left: rem(-528px);
					color: #a64cff;
					transform: rotate(30deg);
				}

				@include create-breakpoint(ll) {
					font-size: rem(1080px);
					bottom: rem(-540px);
					transform: rotate(45deg);
				}
			}
		}

		&:nth-child(odd) {

			.column.has-content {

				&:before {
					@include create-breakpoint(l) {
						left: rem(-80px);
					}
				}
			}

			.column.has-image {

				@include create-breakpoint(l) {
					order: -1;
				}

				.media {
					right: auto;

					@include create-breakpoint(l) {
						left: rem(-70px);
					}
				}

				.number {
					right: rem(56px);
					left: auto;
				}
			}

			.media:after {

				@include create-breakpoint(l) {
					@include icon($icon-product-step);
					z-index: -1;
					position: absolute;
					font-size: rem(768px);
					bottom: rem(-448px);
					left: rem(240px);
					color: #a64cff;
					transform: rotate(-60deg);
				}

				@include create-breakpoint(lm) {
					font-size: rem(880px);
					bottom: rem(-544px);
				}

				@include create-breakpoint(ll) {
					font-size: rem(1080px);
					bottom: rem(-688px);
					transform: rotate(-60deg);
				}
			}
		}

		.icons {
			position: absolute;
			top: rem(10px);
			left: 0;

			@include create-breakpoint(ml) {
				left: rem(20px);
			}

			@include create-breakpoint(t) {
				left: rem(-80px);
			}

			@include create-breakpoint(l) {
				left: 0;
			}

			@include create-breakpoint(ll) {
				left: rem(-80px);
			}
		}

		.icon {
			position: relative;
			width: rem(62px);
			height: rem(62px);
			border: 2px solid #fff;
			border-radius: 50%;

			&:before {
				position: absolute;
				font-size: rem(40px);
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&.is-apple-core:before {
				@include icon($icon-apple-core);
			}

			&.is-chewing:before {
				@include icon($icon-chewing);
			}

			&.is-digesting:before {
				@include icon($icon-digesting);
			}

			&.is-flame:before {
				@include icon($icon-flame);
			}

			&.is-sprout:before {
				@include icon($icon-plant);
			}

			&.is-brain:before {
				@include icon($icon-brain);
			}

			+ .icon {
				margin: rem(32px) 0 0;
			}
		}

		&.animate {

			.column.has-content {
				animation: fade-in-right 0.3s ease 1 both;
			}

			.column.has-image {
				animation: fade-in-left 0.3s ease 1 both;
			}
		}

		&.is-last-step {

			.media:after {
				display: none;
			}
		}

		.content a {
			color: $color-tritary;

			&:before,
			&:after {
				background: $color-tritary;
			}
		}
	}

	&.is-brand-statement {

		.content-block__content {
			background: $color-news-list;
			border-radius: rem(28px);
			padding: rem(200px) rem(40px) rem(40px);
			color: $color-text;
			text-align: center;

			@include create-breakpoint(t) {
				padding: rem(200px) rem(80px) rem(55px);
			}

			@include create-breakpoint(l) {
				padding: rem(200px) rem(120px) rem(70px);
			}

			.js & {
				opacity: 0;
			}
		}

		.content-block__content.has-icon {
			position: relative;

			&:before {
				position: absolute;
				top: rem(42px);
				left: 50%;
				transform: translateX(-50%);
				font-size: rem(120px);
				color: $color-primary;

				@include create-breakpoint(ml) {
					top: rem(64px);
				}
			}
		}

		&.is-light-bulb.content-block__content:before {
			@include icon($icon-light-bulb);
		}

		&.is-waste .content-block__content:before {
			@include icon($icon-waste);
		}

		&.animate {

			.content-block__content {
				animation: fade-in 0.3s ease both;
			}
		}
	}

	&.is-products-block {
		padding-top: rem(60px);

		@include create-breakpoint(t) {
			margin-top: rem(80px);
		}

		@include create-breakpoint(l) {
			margin-top: rem(120px);
		}

		.title.is-big + .content {
			padding-left: 0;
		}

		.title + .media {
			margin-top: rem(60px);
		}

		.content-block__content {
			max-width: rem(496px);

			.js & {
				opacity: 0;
			}
		}

		.column.has-media {

			.js & {
				opacity: 0;
			}
		}

		.image-big {
			padding: rem(60px) 0 0 0;

			@include create-breakpoint(t) {
				padding: rem(80px) 0 0 0;
			}

			@include create-breakpoint(l) {
				padding: rem(120px) 0 0 rem(404px);
			}

			.js & {
				opacity: 0;
			}
		}

		.round-text {
			display: none;
			position: absolute;
			left: rem(-256px);
			bottom: rem(-128px);

			@include create-breakpoint(l) {
				display: block;
			}

			@include create-breakpoint(lm) {
				bottom: 0;
			}

			@include create-breakpoint(ds) {
				left: rem(-160px);
			}

			@include create-breakpoint(d) {
				left: rem(-128px);
			}
		}

		&.animate {

			.content-block__content {
				animation: fade-in 0.3s ease both;
			}

			.image-big {
				animation: fade-in 0.3s ease 0.2s both;
			}

			.column.has-media {
				animation: fade-in 0.3s ease 0.1s both;
			}
		}
		/*		&:before,
		&:after {
			position: absolute;
			z-index: -1;
			content: '';
			width: rem(1264px);
			height: rem(1264px);
			background: url(../../images/spot.png) no-repeat;
			background-size: cover;
		}

		&:before {
			top: rem(-364px);
			left: rem(-632px);
		}

		&:after {
			top: rem(512px);
			right: rem(-632px);

			@include create-breakpoint(l) {
				top: rem(64px);
			}

			@include create-breakpoint(ll) {
				right: 0;
			}
		}*/
	}

	&.is-contact-block {

		.contact-information {
			margin: 0 0 rem(40px);
		}

		.social-media {
			@extend %social-media-green;
		}
	}

	&.is-faq-block {

		.title.is-big + .content {
			padding-left: 0;
		}

		.faq-title {
			margin: 0 0 rem(20px);
		}

		.content-block__content {
			margin-left: auto;

			@include create-breakpoint(l) {
				max-width: rem(500px);
			}
		}

		.home & {
			background: $color-yellow;
			padding-bottom: rem(60px);

			@include create-breakpoint(t) {
				padding-bottom: rem(80px);
			}

			@include create-breakpoint(l) {
				padding-bottom: rem(120px);
			}

			.title {
				color: $color-primary;

				@include create-breakpoint(lm) {
					margin-left: rem(-64px);
				}
			}

			.dropdown {
				background: $color-primary;
				color: $color-yellow;

				label {
					color: #fff;

					&:before {
						color: #fff;
					}
				}

				a {
					color: #fff;

					&:before {
						background: #fff;
					}
				}
			}

			+ .request-call-block {
				margin-top: 0;
			}

			&:before,
			&:after {
				position: absolute;
				content: '';
				transition-delay: .2s;
			}

			&:before {
				width: rem(84px);
				height: rem(100px);
				top: rem(-50px);
				right: rem(20px);
				background-image: url(../../images/strawberry.png);
				background-size: cover;

				@include create-breakpoint(l) {
					right: rem(252px);
				}
			}

			&:after {
				display: none;
				width: rem(188px);
				height: rem(148px);
				bottom: rem(-68px);
				left: rem(20px);
				background-image: url(../../images/banana/banana-280.png);
				background-size: cover;

				@include create-breakpoint(t) {
					display: block;
				}

				@include create-breakpoint(ds) {
					bottom: rem(120px);
				}

				@include create-breakpoint(d) {
					left: rem(220px);
				}
			}
		}
	}

	&.is-slider-block {
		padding-bottom: rem(60px);

		@include create-breakpoint(t) {
			padding-bottom: rem(80px);
		}

		@include create-breakpoint(l) {
			padding-bottom: rem(120px);
		}

		.content-block__content {
			max-width: rem(638px);

			@include create-breakpoint(l) {
				padding: 0 rem(76px) 0 0;
				margin-left: auto;
			}
		}

		.content-block__slider {
			position: relative;
		}

		.swiper-container {
			overflow: hidden;
			border-radius: rem(28px);

			@include create-breakpoint(l) {
				border-radius: rem(28px) 0 0 rem(28px);
				width: calc(100% + 32px);
			}

			@media only screen and (min-width:1616px) {
				width: calc(100% + 50vw - 808px);
			}
		}
	}

	&.is-big-image {

		.content-block__content {
			max-width: rem(764px);
			margin: 0 0 rem(64px);

			@include create-breakpoint(l) {
				padding-left: rem(64px);
			}
		}

		.media {
			overflow: hidden;
			max-width: rem(1056px);
			margin-left: auto;
			border-radius: rem(28px);
		}
	}

	&.is-quote {
		margin: rem(120px) 0 rem(120px);

		@include create-breakpoint(t) {
			margin: rem(180px) 0 rem(180px);
		}

		@include create-breakpoint(l) {
			margin: rem(240px) 0 rem(240px);
		}

		.container {

			@include create-breakpoint(t) {
				display: flex;
				justify-content: flex-end;
				align-items: flex-start;
			}
		}

		.content-block__content-container {
			@include font-title;
			display: flex;
			align-items: center;
			background: $color-yellow;
			min-height: rem(660px);
			max-width: rem(600px);
			padding: rem(40px) rem(20px) rem(140px) rem(20px);
			border-radius: rem(28px);
			box-sizing: border-box;

			@include create-breakpoint(ml) {
				padding: rem(40px) rem(64px) rem(140px) rem(64px);
			}

			@include create-breakpoint(t) {
				max-width: rem(600px);
				padding: rem(40px) rem(124px) rem(40px) rem(208px);
			}

			@include create-breakpoint(l) {
				max-width: rem(720px);
				padding: rem(40px) rem(84px) rem(40px) rem(158px);
			}

			@include create-breakpoint(lm) {
				max-width: rem(840px);
				padding: rem(40px) rem(124px) rem(40px) rem(258px);
			}

			strong {
				color: $color-primary;
				font-weight: 400;
			}

			.quote-person {
				@include font-text;
				color: $color-primary;
			}
		}

		.media {
			position: relative;
			z-index: 0;
			max-width: 85%;
			border-radius: rem(28px);
			margin: rem(-100px) 0 0 0;
			overflow: hidden;

			@include create-breakpoint(t) {
				order: -1;
				max-width: rem(496px);
				margin: rem(64px) rem(-140px) 0 0;
			}
		}

		.round-text {
			position: absolute;
			z-index: -1;
			top: rem(-96px);
			left: rem(-192px);
		}
	}

	&.is-text-with-slider {

		.content-block__slider {
			padding: 0 0 rem(42px);

			&.has-swiper {
				position: relative;
			}

			&:not(.has-swiper) {

				@media only screen and (min-width:1616px) {
					width: calc(100% + 50vw - 808px);
				}
			}

			.column {
				max-width: rem(560px);
			}

			.swiper-slide {
				max-width: rem(496px);
			}

			.swiper-container {
				overflow: hidden;
				width: calc(100% + 1.25rem);


				@include create-breakpoint(l) {
					width: calc(100% + 2rem);
				}

				@media only screen and (min-width:1616px) {
					width: calc(100% + 50vw - 808px);
				}
			}

			.swiper-button-container {
				display: none;
				z-index: 1;
				position: absolute;
				margin: -21px 0 0 0;
				top: 50%;
				right: 0;
				transform: translateY(-50%);

				@include create-breakpoint(t) {
					display: block;
				}

				@include create-breakpoint(l) {
					right: auto;
					left: rem(-32px);
				}

				@include create-breakpoint(ds) {
					/*					right: rem(110px);
					left: auto;*/
				}

				.swiper-button-prev {
					margin: 0 0 1rem !important
				}
			}
		}
	}

	&.is-testimonial-slider {
		padding: rem(60px) 0 rem(160px);
		margin: rem(60px) 0;

		@include create-breakpoint(t) {
			padding: rem(80px) 0 rem(180px);
			margin: rem(80px) 0;
		}

		@include create-breakpoint(l) {
			padding: rem(220px) 0 rem(120px);
			margin: rem(120px) 0;
		}

		.round-text.is-our-community {
			position: absolute;
			bottom: 0;
			left: 50%;

			@include create-breakpoint(l) {
				top: 0;
				bottom: auto;
			}

			@include create-breakpoint(ll) {
				margin-left: rem(-304px);
			}
		}
	}
}