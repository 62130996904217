﻿@use '../custom' as *;
@use '../mixins' as *;

.hamburger {
	@include font-text-bigger;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 25%;
	height: rem(64px);
	padding: 0;
	background: $color-primary;
	color: #fff;
	transition: background $transition-time ease, box-shadow $transition-time ease;
	cursor: pointer;

	@include create-breakpoint(l) {
		justify-content: initial;
		width: max-content;
		border-radius: rem(32px);
		padding: 0 rem(40px) 0 rem(82px);
		box-shadow: 4px 9px 30px 0px rgba(0, 0, 0, 0.2);
	}

	.hamburger__icon {
		position: relative;
		width: 20px;
		height: 3px;
		border-radius: 1px;
		margin-right: rem(14px);
		text-indent: -999999px;
		background: #fff;
		transition: background $transition-time ease;

		@include create-breakpoint(ml) {
			width: 24px;
		}

		@include create-breakpoint(l) {
			position: absolute;
			margin: 0;
			top: 50%;
			left: rem(40px);
			transform: translateY(-50%);
		}

		&:before,
		&:after {
			position: absolute;
			content: '';
			width: 20px;
			height: 3px;
			border-radius: 1px;
			left: 4px;
			background: #fff;
			transition: transform $transition-time ease, background $transition-time ease;

			@include create-breakpoint(ml) {
				width: 24px;
			}
		}

		&:before {
			transform: translateY(-8px);
		}

		&:after {
			transform: translateY(8px);
		}
	}

	.hamburger__text {
		display: none;
		padding: 0;
		transition: color $transition-time ease;

		&:before {
			content: attr(data-text-open);
		}

		@include create-breakpoint(ml) {
			display: block;
		}
	}

	&:hover,
	&:focus {
		background: lighten($color-primary, 10%);
	}
}

%hamburger-active {

	@include create-breakpoint(l) {
		background: $color-tritary;
		color: $color-primary;
	}

	.hamburger__icon {
		background: transparent;

		&:before {
			transform: translateY(0) rotate(45deg);

			@include create-breakpoint(l) {
				background: $color-primary;
			}
		}

		&:after {
			transform: translateY(0) rotate(-45deg);

			@include create-breakpoint(l) {
				background: $color-primary;
			}
		}
	}

	.hamburger__text {

		&:before {
			content: attr(data-text-closed);
		}
	}

	&:hover,
	&:focus {

		@include create-breakpoint(l) {
			background: mix($color-primary, $color-tritary, 50%);
		}
	}
}
