﻿@use '../custom' as *;
@use '../mixins' as *;

.media {

	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-portrait {
		@include responsive-container-psudo(600, 800);
	}

	&.is-landscape {
		@include responsive-container-psudo(800, 450);
	}

	&.is-product-big {
		@include responsive-container-psudo(1148, 478);
		object-fit: contain;
	}

	&.is-industry {
		@include responsive-container-psudo(1056, 594);
		object-fit: contain;
	}

	&.has-no-crop {

		img {
			width: auto;
			margin: 0 auto;

			@include create-breakpoint(l) {
				margin: 0 0 0 auto;
			}
		}
	}

	&.has-placeholder {
		//background: #c9d8d5 url(../../images/logo-pheno-key-placeholder.svg) center no-repeat;
		background-size: rem(223px) rem(77px);
	}

	img {
		width: 100%;
		height: auto;
	}

	.caption {
		@include font-caption;
		position: absolute;
		z-index: 2;
		padding: rem(12px) rem(32px);
		bottom: 0;
		left: 0;
		color: #fff;
		font-weight: 300;
	}

	&.has-caption {
		position: relative;

		&:after {
			position: absolute;
			z-index: 1;
			content: '';
			width: 100%;
			height: rem(120px);
			bottom: 0;
			left: 0;
			background: linear-gradient(0deg, rgba(80,1,1,1) 0%, rgba(80,1,1,0) 75%);
			opacity: 0.5;
		}
	}

	&.has-rounded-corners {
		border-radius: rem(28px);
		overflow: hidden;
	}

	.media__video {

		&:before {
			@include icon($icon-play);
			position: absolute;
			z-index: 2;
			width: rem(64px);
			height: rem(64px);
			padding: 0 0 0 rem(2px);
			border-radius: 50%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(1);
			background: $color-tritary;
			transition: transform $transition-time ease;
			//box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.4);
			font-size: rem(24px);
			text-align: center;
			line-height: rem(64px);
			color: $color-primary;

			@include create-breakpoint(t) {
				width: rem(120px);
				height: rem(120px);
				font-size: rem(48px);
				line-height: rem(120px);
			}
		}

		&:hover {

			&:before {
				transform: translate(-50%, -50%) scale(1.1);
			}
		}

		&.is-relative {
			position: relative;
		}
	}

	&.is-filling {

		img,
		video {
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&.has-hover {
		overflow: hidden;

		img {
			transition: transform $transition-time ease;
			transform: scale(1);
		}

		&:hover img {
			transform: scale(1.2);
		}
	}
}
