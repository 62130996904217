﻿@use '../custom' as *;
@use '../mixins' as *;
@use "sass:math";

.vacancy-overview {
	background: $color-news-list;
	padding-bottom: rem(60px);
	margin-top: rem(60px);

	@include create-breakpoint(t) {
		padding-bottom: rem(80px);
		margin-top: rem(80px);
	}

	@include create-breakpoint(l) {
		padding-bottom: rem(120px);
		margin-top: rem(120px);
	}

	h2.title {
		@include font-title-big;
		text-align: center;
		margin: 0 0 rem(64px);
	}

	.column {

		&:nth-child(6n + 1) .vacancy-overview__item:not(.is-open-application):after {
			width: rem(59px);
			height: rem(86px);
			background-image: url(../../images/Vacancy/vacancy-hop.png);
			transform: rotate(30deg);
		}

		&:nth-child(6n + 2) .vacancy-overview__item:not(.is-open-application):after {
			width: rem(73px);
			height: rem(91px);
			background-image: url(../../images/strawberry.png);
		}

		&:nth-child(6n + 3) .vacancy-overview__item:not(.is-open-application):after {
			width: rem(106px);
			height: rem(63px);
			background-image: url(../../images/Vacancy/caterpillar.png);
		}

		&:nth-child(6n + 4) .vacancy-overview__item:not(.is-open-application):after {
			width: rem(157px);
			height: rem(88px);
			right: rem(50px);
			background-image: url(../../images/egg.png);
		}

		&:nth-child(6n + 5) .vacancy-overview__item:not(.is-open-application):after {
			width: rem(119px);
			height: rem(95px);
			background-image: url(../../images/banana/banana-280.png);
		}

		&:nth-child(6n + 6) .vacancy-overview__item:not(.is-open-application):after {
			width: rem(50px);
			height: rem(92px);
			background-image: url(../../images/Vacancy/avacado.png);
		}
	}

	.vacancy-overview__item {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		overflow: hidden;
		min-height: rem(496px);
		padding: rem(64px);
		border-radius: rem(30px);
		background: $color-primary;
		transition: background $transition-time ease-in-out;

		&:before {
			position: absolute;
			content: '';
			width: rem(58px);
			height: rem(64px);
			top: rem(32px);
			right: rem(32px);
			background: url(../../images/footer-vector-logo.png) no-repeat;
			background-size: contain;
		}

		&:after {
			position: absolute;
			content: '';
			right: rem(100px);
			bottom: rem(32px);
			background-repeat: no-repeat;
			background-size: contain;
		}

		&:hover {
			background: lighten($color-primary, 10%);
		}

		&.is-open-application {
			background: $color-tritary;
			color: $color-primary;

			&:before {
				background: url(../../images/Vacancy/circ-logo-purple.png) no-repeat;
			}

			.vacancy-overview__title,
			.vacancy-overview__link {
				color: $color-primary;

				&:before {
					background: $color-primary;
				}
			}

			&:hover {
				background: lighten($color-tritary, 10%);
			}
		}
	}

	.vacancy-overview__title {
		color: $color-tritary;
		margin: 0 0 rem(28px);
	}

	.vacancy-overview__link {
		@include font-text-bigger;
		@include underline-effect-base(#fff);
		color: #fff;

		&:before {
			transform: scaleX(1);
		}

		&:hover {

			&:before {
				transform-origin: left;
				transform: scaleX(0);
			}
		}
	}

	.vacancy-overview__spot {
		position: absolute;
		z-index: -1;
		display: block;
		width: rem(600px);
		height: rem(600px);
		bottom: -50%;
		left: -50%;
		background: url(../../images/spot.png) no-repeat;
		background-size: contain;
	}

	+ .call-to-action {
		background: $color-news-list;
		padding-top: rem(60px);
		margin: 0;

		@include create-breakpoint(t) {
			padding-top: rem(80px);
		}

		@include create-breakpoint(l) {
			padding-top: rem(120px);
		}
	}
}