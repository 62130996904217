﻿@use '../custom' as *;
@use '../mixins' as *;

.social-media {
	overflow: hidden;
	padding: 0;
	margin: rem(-6px);
	list-style: none;

	li {
		display: inline-block;
		margin: rem(6px);
	}

	a {
		position: relative;
		display: block;
		width: rem(48px);
		height: rem(48px);
		border-radius: 50%;
		color: $color-primary;
		font-size: rem(24px);
		line-height: rem(24px);
		text-decoration: none;
		transition: all 150ms ease-in-out;
		overflow: hidden;
		background: #fff;

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-indent: 0;

			&:before {
				position: absolute;
				transform: translate(-50%,-50%);
			}
		}

		&:hover,
		&:focus {
			transform: translateY(-4px);
		}
	}
}

%social-media-green {

	a {
		background: $color-tritary;
		color: $color-primary;
	}
}

.social-media__container {
	@include font-text-bigger;
	display: flex;
	align-items: center;
	color: $color-tritary;
	margin: 0;

	dt {
		padding: 0 rem(12px) 0 0;
	}

	dd {
		margin: 0;
	}
}