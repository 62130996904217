﻿@use '../custom/' as *;
@use '../mixins' as *;

.footer-logo {
	width: rem(153px);
	height: rem(166px);
	background-repeat: no-repeat;
	background-size: cover;

	&.loaded {
		background-image: url(../../images/footer-vector-logo.png);
	}
}