﻿@use '../custom' as *;
@use '../mixins' as *;

.testimonials {
	margin-top: rem(60px);

	@include create-breakpoint(t) {
		margin-top: rem(80px);
	}

	@include create-breakpoint(l) {
		margin-top: rem(120px);
	}

	.column {
		max-width: rem(564px);
		transition: opacity $transition-time ease;

		&.is-hidden {
			opacity: 0;
		}

		&.no-results-fount {
			display: flex;
			align-items: center;
		}
	}
}

.testimonial {
	position: relative;
	z-index: 0;
	overflow: hidden;
	min-height: rem(662px);
	border-radius: rem(28px);

	img {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	.testimonial__hgroup {
		position: absolute;
		z-index: 1;
		padding: rem(42px) rem(96px) rem(42px) rem(46px);
		bottom: 0;
		left: 0;
		color: #fff;

		.testimonial__hgroup-filter {
			@include font-text-bigger;
			color: $color-tritary;
			font-weight: 300;
		}
	}

	.testimonial__hover {
		display: flex;
		align-items: center;
		position: relative;
		z-index: 2;
		width: 100%;
		min-height: rem(662px);
		padding: rem(64px);
		background: rgba($color-primary, 0.95);
		color: #fff;
		opacity: 0;
		transition: opacity $transition-time ease;

		a {
			color: $color-tritary;

			&:before,
			&:after {
				background: $color-tritary;
			}
		}
	}

	.testimonial__contacts {
		position: absolute;
		z-index: 1;
		width: rem(32px);
		top: rem(160px);
		left: rem(48px);

		@include create-breakpoint(ll) {
			top: rem(52px);
			left: rem(80px);
		}

		@include create-breakpoint(ll) {
			left: rem(48px);
		}

		.testimonial__contact {
			display: block;
			width: rem(48px);
			padding: rem(8px);
			border-radius: 50%;
			margin: 0 0 rem(8px);

			&:before {
				font-size: rem(32px);
				color: $color-tritary;
			}

			&.is-email:before {
				@include icon($icon-mail);
			}

			&.is-phone:before {
				@include icon($icon-phone);
			}

			&:hover,
			&:focus {
				background: #fff;

				&:before {
					color: $color-primary;
				}
			}
		}
	}

	&:before {
		@include icon($icon-plus);
		position: absolute;
		bottom: rem(24px);
		right: rem(24px);
		font-size: rem(48px);
		z-index: 1;
		color: #fff;

		.is-employee-slider & {
			display: none;
		}
	}

	&:hover,
	&:focus {

		.testimonial__hover {
			opacity: 1;
		}
	}
}