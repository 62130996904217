﻿@use '../custom' as *;
@use '../mixins' as *;

%link {
	@include font-text-bigger;
	position: relative;
	color: $color-primary;
	font-weight: 400;

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 100%;
		left: 0;
		background: $color-primary;
	}

	&:before {
		z-index: 1;
		height: 2px;
		bottom: rem(-4px);
	}

	&:after {
		z-index: 1;
		height: 4px;
		bottom: rem(-5px);
		transform: scaleX(0);
		transition: transform 0.3s ease;
		transform-origin: left;
	}

	&:hover,
	&:focus {

		&:after {
			transform: scaleX(1);
		}
	}

	&.is-tritary {
		color: $color-tritary;

		&:before,
		&:after {
			background: $color-tritary;
		}
	}
}

%link-small {
	@include font-text;
	@extend %link;
}

.link {
	@extend %link;
}