﻿@use '../custom/' as *;
@use '../mixins' as *;

.list {
	@include font-list;
	padding: 0;
	list-style: none;

	a {
		display: block;
		padding: rem(20px) rem(24px);
		border-top: 1px solid rgba(#fff, 0.2);
		transition: background $transition-time ease;

		&:hover,
		&:focus {
			background: rgba(#fff, 0.1);
		}
	}

	li:last-child a {
		border-bottom: 1px solid rgba(#fff, 0.2);
	}
}