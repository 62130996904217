﻿@use "node_modules/normalize.css/normalize.css";

// Remove chrome focus
* {
	outline: none;
}

// Extra custom normalize
h1,
h2,
p,
figure,
ul,
ol {
	margin: 0;
}

a,
h1,
h2,
h3,
div,
ul,
li,
dl,
dt,
dd,
form,
input,
textarea,
header,
div,
nav,
label {
	box-sizing: border-box;
}

::before,
::after {
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
}

img {
	vertical-align: middle;
}

// Remove border-radius for ios
input,
textarea {
	border-radius: 0;
	-webkit-appearance: unset;
}

input[type=checkbox] {
	-webkit-appearance: checkbox;
}

input[type=radio] {
	-webkit-appearance: radio;
}