﻿@use '../custom' as *;
@use '../mixins' as *;

.slide {
	position: relative;
	z-index: 0;
	min-height: 100vh;

	.js & {
		min-height: var(--vh);
	}

	.slide__background-image,
	.slide__background-video {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	.slide__colored-background.is-yellow {
		display: flex;
		align-items: center;
		width: 100%;
		background: $color-yellow;
	}

	&.is-image-slide {

		.image-slide__image {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: auto;
			top: 0;
			right: 0;
			opacity: 0;
			transition: opacity 0.2s 0.2s ease-out, transform 0.2s 0.2s ease-out;

			@media only screen and (min-width: 1024px) and (min-height: 768px) {
				width: rem(324px);
				height: rem(324px);
				border-radius: 50%;
				top: rem(80px);
				left: rem(123px);
				transform: none;
				background: rgba(#fff, 0.75);
			}

			@media only screen and (min-width: 1024px) and (min-height: 768px) {
				top: rem(110px);
				left: rem(158px);
			}

			.button {

				@media only screen and (max-width: 1023px) {
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
					background: $color-light-yellow;
					color: $color-text;
					border-radius: 0;
					display: block;
					text-align: center;

					&:after {
						@include icon($icon-arrow-right);
						position: relative;
						font-size: rem(24px);
						left: rem(10px);
					}
				}

				@media only screen and (max-height: 767px) {
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
					background: $color-light-yellow;
					color: $color-text;
					border-radius: 0;
					display: block;
					text-align: center;

					&:after {
						@include icon($icon-arrow-right);
						position: relative;
						font-size: rem(24px);
						left: rem(10px);
					}
				}

				@media only screen and (min-width: 1024px) and (min-height: 768px) {
					position: absolute;
					bottom: rem(32px);
					left: rem(96px);
				}
			}

			img {
				display: none;
				position: absolute;
				z-index: -1;
				width: rem(192px);
				height: auto;
				top: 100%;
				left: rem(-16px);
				background: rgba($color-light-yellow, 0.8);
				border-radius: 0 0 50%;

				@media only screen and (min-width: 1024px) and (min-height: 768px) {
					display: block;
					position: relative;
					z-index: 0;
					width: 100%;
					border: rem(8px) solid #fff;
					border-radius: 50%;
					top: auto;
					left: auto;
					background: transparent;
				}
			}
		}

		.image-slide__image-text {

			@include create-breakpoint(l) {
				display: none;
			}
		}

		.title {
			position: absolute;
			z-index: 1;
			width: 100%;
			max-width: rem(536px);
			padding: 0 rem(20px);
			bottom: rem(60px);
			left: 0;
			color: #fff;
			opacity: 0;
			transform: translateY(100px);
			transition: opacity 0.2s 0.2s ease-out, transform 0.2s 0.2s ease-out;

			@include create-breakpoint(t) {
				left: rem(60px);
			}

			@include create-breakpoint(l) {
				left: rem(100px);
			}

			@include create-breakpoint(ll) {
				bottom: rem(120px);
				left: rem(135px);
			}

			strong {
				color: $color-tritary;
				font-weight: normal;
			}
		}

		.slide__background-image {
			z-index: -2;
		}

		&:before {
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: linear-gradient(90deg, rgba(80,1,1,1) 0%, rgba(255,122,0,1) 100%);
			opacity: 0.4;
		}

		&.animate {

			.image-slide__image {
				transform: scale(1) translateX(0);
				opacity: 1;

				@include create-breakpoint(t) {
					//transform: scale(1) translateX(50%);
				}

				@include create-breakpoint(l) {
					transform: scale(1);
				}
			}

			.round-text {
				opacity: 1;
			}

			.title {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}

	&.is-text-slide {
		background: $color-primary;

		.container {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			text-align: center;
			color: #fff;
			opacity: 0;

			&:first-child {
				opacity: 1;
			}
		}
	}

	&.is-small-image-slide {
		min-height: auto;
		padding: rem(60px) 0 rem(240px);

		@include create-breakpoint(t) {
			padding: rem(90px) 0 rem(190px);
		}

		@include create-breakpoint(l) {
			padding: rem(140px) 0 rem(240px);
		}

		.container {
			max-width: rem(1096px);
		}

		.media {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: hidden;
			z-index: 0;
			border-radius: rem(28px);
			min-height: rem(594px);

			img {
				position: absolute;
				z-index: -1;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				object-fit: cover;
				object-position: center;
			}
		}

		.title {
			color: #fff;
			padding: rem(20px) rem(20px) rem(56px);
			max-width: rem(864px);

			@include create-breakpoint(t) {
				padding: rem(20px) rem(20px) rem(76px);
			}

			@include create-breakpoint(l) {
				padding: rem(20px) rem(20px) rem(96px);
			}

			strong {
				color: $color-tritary;
				font-weight: normal;
			}
		}

		.slide__container {
			position: relative;
		}

		.round-text {
			position: absolute;
			z-index: -1;
			right: rem(-120px);
			bottom: rem(-180px);
			width: rem(408px);
			height: rem(408px);

			@include create-breakpoint(t) {
			}

			@include create-breakpoint(ll) {
				top: rem(128px);
				bottom: auto;
				width: rem(608px);
				height: rem(608px);
				right: rem(-280px);
			}
		}
	}

	&.is-round-images-slide {
		display: flex;
		flex-wrap: wrap;

		.images {
			position: relative;
			z-index: 1;
			width: 100%;
			height: 50vh;

			@include create-breakpoint(l) {
				width: 56%;
				height: auto;
			}
		}

		.images-container {
			position: relative;
			width: 100%;
			max-width: rem(1068px);
			height: 100%;
			margin-left: auto;
		}

		.images-block {
			position: absolute;
			width: 80vw;
			max-width: rem(800px);
			left: 50%;
			transform: translateX(-50%);

			@include create-breakpoint(ml) {
				width: 60vw;
			}

			@include create-breakpoint(t) {
				width: 45vw;
			}

			@include create-breakpoint(l) {
				width: 41.67vw;
				top: 50%;
				left: rem(20px);
				transform: translateY(-50%);
			}

			@include create-breakpoint(ll) {
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.round-text {
				position: absolute;
				z-index: 4;
				width: 74%;
				height: 74%;
				margin: rem(-37%) 0 0 rem(-37%);
				top: 50%;
				left: 50%;
			}

			&:nth-child(3n + 1) .round-text {

				&.loaded {
					background: url('../../images/circle text/energy-is-everything-white.svg')
				}
			}

			&:nth-child(3n + 2) .round-text {

				&.loaded {
					background: url('../../images/circle text/shoes-is-life-white.svg')
				}
			}

			&:nth-child(3n + 3) .round-text {

				&.loaded {
					background: url('../../images/circle text/positive-energy-white.svg')
				}
			}
		}

		.images-block {
			opacity: 0;

			img {
				height: auto;
				border-radius: 50%;
			}

			> img:nth-child(1),
			> picture:nth-child(1) img {
				position: relative;
				width: 100%;
				z-index: 3;
			}

			> img:nth-child(2),
			> picture:nth-child(2) img {
				position: absolute;
				width: 30vw;
				max-width: rem(320px);
				top: 0;
				right: -8%;
				z-index: 1;

				@include create-breakpoint(t) {
					right: -25%;
					width: 22vw;
				}

				@include create-breakpoint(l) {
					width: 16.7vw;
				}
			}

			> img:nth-child(3),
			> picture:nth-child(3) img {
				position: absolute;
				width: 30vw;
				max-width: rem(320px);
				bottom: -16%;
				left: 0;
				z-index: 2;

				@include create-breakpoint(t) {
					width: 22vw;
				}

				@include create-breakpoint(l) {
					width: 16.7vw;
				}
			}

			> img:nth-child(4),
			> picture:nth-child(4) img {
				position: absolute;
				width: 25vw;
				max-width: rem(240px);
				right: -8%;
				bottom: -8%;
				z-index: 4;

				@include create-breakpoint(t) {
					width: 18vw;
				}

				@include create-breakpoint(l) {
					width: 12.5vw;
				}
			}

			&:first-child {
				opacity: 1;
			}
		}

		.text {
			display: flex;
			align-items: center;
			width: 100%;
			//height: 40vh;
			order: -1;

			@include create-breakpoint(l) {
				width: 44%;
				height: 100%;
				order: 0;
			}
		}

		.text-container {
			width: 100%;
			max-width: rem(720px);
		}

		.text-content-container {
			padding: 0 rem(20px) 0;
			max-width: rem(536px);
			margin: 0 auto;

			@include create-breakpoint(l) {
				margin: 0 0 0 auto;
				padding: 0 rem(80px) 0 rem(20px);
			}

			@include create-breakpoint(ll) {
				margin: 0 0 0 auto;
				padding: 0 rem(20px) 0;
			}
		}

		.title {
			margin: 0 0 rem(30px);

			@include create-breakpoint(l) {
				margin: 0 0 rem(60px);
			}
		}

		.content-container {
			position: relative;

			.content {
				position: absolute;
				opacity: 0;
				top: 0;
				left: 0;
				visibility: hidden;

				&:first-child {
					position: static;
					opacity: 1;
					visibility: visible;
				}

				.arrow {
					display: block;
					color: $color-primary;

					&:after {
						@include icon($icon-arrow-right);
						position: relative;
						font-size: rem(20px);
						left: rem(8px);
						transition: left $transition-time ease-in-out;
					}

					&:hover:after {
						left: rem(12px);
					}
				}

				@include create-breakpoint(l) {
					@include font-menu-text;
				}
			}
		}
	}

	&.is-banana-block-yellow {
		display: flex;
		padding-top: rem(250px);
		min-height: initial;

		@include create-breakpoint(t) {
			padding-top: rem(250px);
		}

		@include create-breakpoint(l) {
			padding-top: rem(250px);
		}

		.slide__colored-background {
			padding: rem(60px) 0;

			@include create-breakpoint(t) {
				padding: rem(80px) 0;
			}

			@include create-breakpoint(l) {
				padding: rem(120px) 0;
			}
		}

		.js & {
			min-height: initial;
		}

		.title {
			color: $color-primary;
			max-width: rem(700px);
		}

		.round-text {
			position: absolute;
			width: rem(408px);
			height: rem(408px);
			top: 0;
			right: rem(-64px);
			z-index: -1;

			@include create-breakpoint(t) {
				z-index: 0;
			}

			@include create-breakpoint(lm) {
				width: rem(608px);
				height: rem(608px);
				top: rem(-64px);
			}

			@include create-breakpoint(ll) {
				right: rem(128px);
			}

			@include create-breakpoint(ds) {
				right: rem(264px);
			}
		}
	}

	&.is-banana-block-purple {
		display: flex;
		align-items: center;
		background: $color-primary;
		min-height: initial;
		padding: rem(120px) 0 rem(60px);

		@include create-breakpoint(t) {
			padding: rem(80px) 0;
		}

		@include create-breakpoint(l) {
			padding: rem(120px) 0;
		}

		.js & {
			min-height: initial;
		}

		.container {
			position: relative;
			z-index: 1;
		}

		.banana {
			position: absolute;
			z-index: -1;
			width: rem(280px);
			height: rem(222px);
			top: 0;
			right: 0;
			background: url(../../images/banana/banana-280.png);
			background-size: contain;
			transition: top $transition-time ease;
			transform: translateY(-75%);

			@include create-breakpoint(t) {
				width: rem(400px);
				height: rem(317px);
				transform: translateY(-25%);
			}

			@include create-breakpoint(l) {
				background-image: url(../../images/banana/banana-600.png);
				width: rem(500px);
				height: rem(396px);
				transform: translateY(-50%);
			}

			@include create-breakpoint(lm) {
				width: rem(600px);
				height: rem(475px);
			}

			@include create-breakpoint(ll) {
				background-image: url(../../images/banana/banana-733.png);
				width: rem(733px);
				height: rem(580px);
			}
		}

		.title {
			color: $color-yellow;
			max-width: rem(700px);
			margin: 0 0 rem(60px);
		}

		.content {
			@include font-menu-text;
			max-width: rem(636px);
			color: #fff;

			@include create-breakpoint(ml) {
				padding-left: rem(40px);
			}

			@include create-breakpoint(t) {
				padding-left: rem(80px);
			}

			@include create-breakpoint(l) {
				padding-left: rem(140px);
			}
		}
	}
}

