﻿@use '../custom' as *;
@use '../mixins' as *;

.request-call-block {
	@include font-text-bigger;
	background: $color-news-list;
	padding: rem(120px) 0;

	.request-call-block__header {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-around;
		color: $color-primary;
		border-bottom: 1px solid #dadde5;
		padding: 0 0 rem(36px);
	}

	.request-call-block__link {
		position: relative;
		padding: 0 0 0 rem(48px);
		font-weight: normal;

		&:before {
			position: absolute;
			font-size: rem(24px);
			top: rem(6px);
			left: rem(8px);
		}

		&.is-telephone {

			@include create-breakpoint(l) {
				order: -1;
			}

			&:before {
				@include icon($icon-phone);
			}
		}

		&.is-email:before {
			@include icon($icon-mail);
		}

		span {
			position: relative;

			&:after {
				position: absolute;
				content: '';
				width: 100%;
				left: 0;
				background: $color-primary;
				z-index: 1;
				height: 2px;
				bottom: rem(-5px);
				transform: scaleX(0);
				transition: transform 0.3s ease;
				transform-origin: left;
			}
		}

		&:hover,
		&:focus {

			span:after {
				transform: scaleX(1);
			}
		}
	}

	.request-call-block__content {
		@include font-title;
		font-weight: normal;
		text-align: center;
		padding: rem(66px) 0 0;

		.content {
			max-width: rem(496px);
			margin: 0 auto;
			color: $color-text;
		}
	}

	.logo {
		width: 100%;
		margin: 0 0 rem(32px);

		@include create-breakpoint(l) {
			width: rem(120px);
			margin: 0 0 0;
		}
	}

	+ .call-to-action {
		background: $color-news-list;
		margin-top: 0;
	}
}

.request-call-block-aside__input {
	display: none;

	&:checked + .request-call-block-aside {
		transform: translateX(0);
	}

	&:checked ~ .request-call-block-aside__backdrop {
		visibility: visible;
		opacity: 1;
	}
}

.request-call-block-aside {
	position: fixed;
	z-index: 1001;
	height: 100%;
	width: rem(508px);
	padding: rem(64px);
	top: 0;
	right: 0;
	background: $color-news-list;
	transform: translateX(100%);
	transition: transform $transition-time ease-in-out;

	.title {
		margin: 0 0 rem(40px);
		color: $color-text;
	}

	.input {
		margin: 0 0 rem(16px);
	}

	.email2 {
		position: absolute;
		z-index: -1;
		width: 1px;
		height: 1px;
		top: 0;
		right: 0;
		-webkit-appearance: none;
	}

	.request-call-block-aside__close {
		position: absolute;
		width: rem(24px);
		height: rem(24px);
		top: rem(14px);
		right: rem(21px);
		text-indent: -99999px;
		overflow: hidden;
		cursor: pointer;

		&:after {
			@include icon($icon-close);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(18px);
			text-indent: 0;
		}
	}
}

.request-call-block-aside__backdrop {
	position: fixed;
	z-index: 1000;
	content: '';
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba($color-text, 0.5);
	visibility: hidden;
	opacity: 0;
	transition: all $transition-time ease-in-out;
}

.testimonials + .request-call-block,
.content-block + .request-call-block {
	margin-top: rem(60px);

	@include create-breakpoint(t) {
		margin-top: rem(80px);
	}

	@include create-breakpoint(l) {
		margin-top: rem(120px);
	}
}